import PreviewImage from 'components/Modals/PreviewImage';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const BASE_URL = process.env.REACT_APP_IMAGES;

const SelectFieldImage = ({ label, value, onChange, disabled, onRemove }) => {
  const inputRef = useRef(null);
  const [showImage, setShowImage] = useState(false);

  return (
    <div className="select-field-image">
      <h4>{label}</h4>
      <div className="select-field-image__wrapper">
        <input type="file" accept="image/*" hidden ref={inputRef} onChange={onChange} />
        <input
          type="text"
          value={value || ''}
          className="input-field"
          readOnly
          style={{ paddingRight: value ? (disabled ? '100px' : '180px') : '100px' }}
        />
        {value ? (
          <div className="btn-group">
            {!disabled ? (
              <button className="btn-item btn-delete" onClick={onRemove}>
                Hapus
              </button>
            ) : null}
            <button className="btn-item btn-show" onClick={() => setShowImage(true)}>
              Lihat
            </button>
          </div>
        ) : (
          <button className="btn-upload" onClick={() => inputRef.current.click()} disabled={disabled}>
            Browse..
          </button>
        )}
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage
            image={
              Object.prototype.hasOwnProperty.call(value, 'name') ? URL.createObjectURL(value) : `${BASE_URL}${value}`
            }
            setShow={() => setShowImage(false)}
          />,
          document.getElementById('modal'),
        )}
    </div>
  );
};

export default SelectFieldImage;
