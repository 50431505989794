import ButtonAdd from 'components/Global/ButtonAdd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BusinessPartnerList from './PartnerList';
import { resetSelectedBusiness } from 'features/business-partner/vendorSlice';

const BusinessPartnerVendorComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAdd = () => {
    dispatch(resetSelectedBusiness());
    navigate('/business-partner-list/add-partner');
  };

  return (
    <div className="business">
      <ButtonAdd onClick={handleAdd} label="Tambah Partner" />

      <BusinessPartnerList />
    </div>
  );
};

export default BusinessPartnerVendorComponent;
