import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDriverTasks, getDetailDriverTask, updateDriverTask } from 'features/drivers/actions';
import Modals from '../Modals';
import TextAreaInput from 'components/Global/TextArea';
import { Button } from 'components/Global';
import { ReactComponent as VehicleIcon } from 'icons/car-blue-bg.svg';

const CancelDriverTaskModal = () => {
  const dispatch = useDispatch();

  // CONTEXT
  const { showToast, setShowCancelDriverTask } = useAppContext();

  // GLOBAL STATE
  const { selected: selectedData } = useSelector((state) => state.driverTaskList);

  // LOCAL STATE
  const [cancelMessage, setCancelMessage] = useState('');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const status = searchParams.get('status');

  // SUBMIT ACTION
  const handleSubmit = async () => {
    // validation
    const validCancelMessage = cancelMessage.replace(/\s/g, '').length !== 0;

    // check if not valid return error toast
    if (!validCancelMessage) {
      return showToast({ type: 'error', message: 'Tulis Alasan Pembatalan' });
    }

    const payload = {
      id,
      fee_per_task: selectedData?.fee_per_task,
      action: 'CANCELING_TASK',
      cancelation_reason: cancelMessage,
    };

    const currentPage = localStorage.getItem('driver_task_page');

    try {
      await dispatch(updateDriverTask(payload)).unwrap();
      setShowCancelDriverTask(false);
      dispatch(
        getAllDriverTasks({
          page: currentPage,
          limit: 10,
          status: status === 'ALL' ? undefined : status?.split(','),
          orderBy: 'booking_start_date',
        }),
      );
      showToast({ type: 'success', message: 'Berhasil cancel task' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Gagal cancel task' });
    }
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getDetailDriverTask(id));
  }, [id]);

  return (
    <Modals
      setState={setShowCancelDriverTask}
      icon={<VehicleIcon fill="#009EF7" width="25px" height="25px" />}
      title={'Alasan Pembatalan Order'}
    >
      <div className="cancel-driver-task">
        {/* TEXT AREA */}
        <div className="cancel-driver-task__message">
          <span>Tulis Keterangan</span>
          <TextAreaInput
            className="cancel-driver-task__message__textarea"
            style={{ height: '292px' }}
            value={cancelMessage}
            onChange={(e) => setCancelMessage(e.target.value)}
            placeholder="Masukkan Keterangan"
          />
        </div>

        {/* BUTTON */}
        <div className="cancel-driver-task__button">
          <Button type="button" width={131} variant="outline" size="md" onClick={() => setShowCancelDriverTask(false)}>
            Kembali
          </Button>
          <Button type="button" width={131} variant="primary" size="md" onClick={handleSubmit}>
            Kirim
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default CancelDriverTaskModal;
