import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as AirportIcon } from 'icons/airport-transfer-filled.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { Button, InputField, SelectFieldAirportPackage, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject, checkPermission, setCurrency } from 'utils/functionality';
import countryCodes from 'utils/country-codes.json';
import { getCustomerData } from 'features/user/slice';
import { fetchOrderById, fetchSummaryOrder, getAirportPackageById } from 'features/orders/actions';
import { getAllVehicleAirportPackage, getVehicleById, getVehicleByIdAirportPackage } from 'features/vehicle/action';
import { getApprovalSettings, updateApprovalOrder } from 'features/approvals/actions';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { ReactComponent as SwitchIcon } from 'icons/switch-icon.svg';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import { format, parseISO, subHours } from 'date-fns';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { getRentalLocationByServices } from 'features/rental-location/actions';
import { getServices } from 'features/services/actions';
import { getShuttleAirportZone, getShuttleTheAirport } from 'features/shuttle-airport/actions';
import moment from 'moment-timezone';
// import InputViolation from 'components/Global/InputViolation';
// import InputAdditionals from 'components/Global/InputAdditionals';

const INITIAL_STATE = {
  booking_price: 0,
  currency: '',
  order_detail: {
    passenger_number: '',
    baggage: '',
    end_booking_date: '',
    end_booking_time: '',
    rental_delivery_location: '',
    rental_delivery_location_detail: '',
    rental_return_location: '',
    rental_return_location_detail: '',
    start_booking_date: '',
    start_booking_time: '',
    vehicle_id: 0,
    location_id: 0,
    loc_time_id: '',
    landing_time: '',
    flight_number: '',
    airport_name: '',
  },
  order_type_id: 0,
  deposit: 0,
  over_time_price: 0,
  over_time: 0,
};

const EditOrder = ({ transactionKey }) => {
  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [flightNumber, setFlightNumber] = useState('');
  const [startRentDate, setStartRentDate] = useState('');
  const [deliveryLocationDetail, setDeliveryLocationDetail] = useState('');
  const [returnLocationDetail, setReturnLocationDetail] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState({
    id: 0,
    name: '',
  });
  const [returnLocation, setReturnLocation] = useState({
    id: 0,
    name: '',
  });
  const [locationData, setLocationData] = useState({
    loc_time_id: '',
    location_id: 0,
  });
  const [selectedCar, setSelectedCar] = useState(undefined);
  const [isSwitch, setIsSwitch] = useState(false);
  const [orderData, setOrderData] = useState(INITIAL_STATE);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  // ADDITIONAL STATE
  // const [additionalList, setAdditionalList] = useState([]);
  // const [additionalPrice, setAdditionalPrice] = useState(0);
  // VIOLIATION STATE
  // const [violationList, setViolationList] = useState([]);
  // const [violationPrice, setViolationPrice] = useState(0);

  const customer = useSelector(getCustomerData);
  const { data: vehicleAirportPackageData, selectedPackageVechile } = useSelector(
    (state) => state.vehicleAirportPackage,
  );
  const vehicleById = useSelector((state) => state.vehicle.selected);
  const { data: summaryData, status: summaryStatus } = useSelector((state) => state.summaryOrder);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { dataZone, dataAirport } = useSelector((state) => state.shuttleAirport);
  const approvalData = useSelector((state) => state.approvals.approvalSetting);
  const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data, status } = useSelector((state) => state.detailOrder);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const isUpdate = searchParams.get('update');

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);

  const getTotalPrice = (total, point, voucher) => {
    let finalPrice = total - point;
    const voucherValue = voucher?.length > 0 ? voucher[0].value : undefined;
    if (voucherValue) {
      finalPrice -= voucherValue;
    }
    return isNaN(finalPrice) ? 0 : finalPrice;
  };

  useEffect(() => {
    if (!rentalServicesData.length) return;

    const serviceSewaMobil = rentalServicesData.find((item) => item.name === 'Sewa Mobil');

    const service_id = serviceSewaMobil?.id;
    let sub_service_id;
    let facility_id;

    sub_service_id = rentalServicesData
      ?.find((item) => item.id === service_id)
      ?.sub_services?.find((item) => item.name === 'Airport Transfer')?.id;
    facility_id = undefined;

    dispatch(getRentalLocationByServices({ service_id, sub_service_id, facility_id }));
  }, [rentalServicesData]);

  useEffect(() => {
    if (status === 'loading' || summaryStatus === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status, summaryStatus]);

  useEffect(() => {
    if (
      !orderData.order_detail.start_booking_date ||
      !orderData.order_detail.start_booking_time ||
      !Object.keys(deliveryLocation).length ||
      !Object.keys(returnLocation).length ||
      !locationData
    )
      return;

    dispatch(
      getAllVehicleAirportPackage({
        pickupTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
        locationId: locationData.id,
        pickUpLocationId: deliveryLocation?.id,
        dropOffLocationId: returnLocation?.id,
      }),
    );
  }, [
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    deliveryLocation,
    returnLocation,
    isSwitch,
    locationData,
  ]);

  useEffect(() => {
    if (
      !orderData.airport_transfer_package_id ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === ''
    )
      return;

    const pickupTrip = `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`;
    dispatch(
      getVehicleByIdAirportPackage({
        id: orderData.airport_transfer_package_id,
        pickupTrip,
        pickUpLocationId: deliveryLocation?.id,
        dropOffLocationId: returnLocation?.id,
        order_id: orderData.transaction_key,
      }),
    );
  }, [
    orderData?.airport_transfer_package_id,
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    deliveryLocation,
    returnLocation,
    isSwitch,
  ]);

  useEffect(() => {
    if (!locationData) return;

    dispatch(getShuttleAirportZone({ locationId: locationData?.id, limit: 100 }));
    dispatch(getShuttleTheAirport({ locationId: locationData?.id, limit: 100 }));
  }, [locationData]);

  useEffect(() => {
    dispatch(fetchOrderById(transactionKey));
  }, [transactionKey]);

  useEffect(() => {
    if (!isUpdate) return;
    if (checkEmptyObject(data)) return;
    const approvalKey = data.is_admin_creation ? 'update-order-to-higher-level' : 'update-order-to-customer';
    dispatch(getApprovalSettings({ limit: 10, key: approvalKey }));
    dispatch(getServices());
  }, [isUpdate, data]);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'order_detail')) return;
    dispatch(
      getAirportPackageById({
        id: data.airport_transfer_package_id,
        pickupTrip: `${data.order_detail?.start_booking_date} ${data.order_detail?.start_booking_time.slice(0, 5)}`,
      }),
    );
    dispatch(getVehicleById(data.order_detail.vehicle_id));
  }, [data]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  useEffect(() => {
    if (selectedCar) {
      setIsPageLoaded(true);
    }
  }, [selectedCar]);

  useEffect(() => {
    if (checkEmptyObject(vehicleById) || isPageLoaded) return;
    setSelectedCar(() => ({
      vehicle_id: vehicleById.id,
      vehicle_name: vehicleById.name,
      max_passenger: vehicleById.max_passanger,
      max_suitecase: vehicleById.max_suitcase,
      category: vehicleById.category.name,
      images: vehicleById.photo?.map((item) => item.name),
      transmission: vehicleById.transmission,
      price_with_driver: vehicleById.price_with_driver,
      slash_price: vehicleById.slash_price,
      licence_number: vehicleById.license_number,
    }));
  }, [vehicleById, isPageLoaded]);

  // useEffect(() => {
  //   if (Object.prototype.hasOwnProperty.call(data, 'order_additional_fees')) {
  //     if (data.order_additional_fees !== null) {
  //       setAdditionalList(data.order_additional_fees);
  //       setAdditionalPrice(0);
  //     }
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (additionalList.length > 0) {
  //     setAdditionalPrice(() => additionalList.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
  //     return;
  //   }
  //   setAdditionalPrice(0);
  // }, [additionalList]);

  // useEffect(() => {
  //   if (Object.prototype.hasOwnProperty.call(data, 'order_violations')) {
  //     if (data.order_violations !== null) {
  //       setViolationList(data.order_violations);
  //       setViolationPrice(0);
  //     }
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (violationList.length > 0) {
  //     setViolationPrice(() => violationList?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
  //     return;
  //   }
  //   setViolationPrice(0);
  // }, [violationList]);

  const rentalLocationHandler = (location) => {
    if (location.id === locationData.id) return;
    if (orderData.order_detail.vehicle_id !== 256) {
      setSelectedCar(undefined);
    }
    setLocationData(location);
    setDeliveryLocation({});
    setReturnLocation({});
    setDeliveryLocationDetail('');
    setReturnLocationDetail('');
    setOrderData({
      ...orderData,
      airport_transfer_package_id: 0,
      order_detail: {
        ...orderData.order_detail,
        rental_delivery_location: '',
        rental_return_location: '',
        rental_delivery_location_detail: '',
        rental_return_location_detail: '',
        airport_name: '',
      },
    });
  };

  const switchHandler = () => {
    setIsSwitch((prevState) => !prevState);
    if (orderData.order_detail.vehicle_id !== 256) {
      setSelectedCar(undefined);
    }
    setDeliveryLocation(returnLocation);
    setReturnLocation(deliveryLocation);
    setDeliveryLocationDetail(returnLocationDetail);
    setReturnLocationDetail(deliveryLocationDetail);
    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        rental_delivery_location: orderData.order_detail.rental_return_location,
        rental_return_location: orderData.order_detail.rental_delivery_location,
        rental_delivery_location_detail: orderData.order_detail.rental_return_location_detail,
        rental_return_location_detail: orderData.order_detail.rental_delivery_location_detail,
      },
      airport_transfer_package_id: 0,
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const backHandler = () => {
    navigate(-1);
  };

  const updateHandler = () => {
    if (
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      isNaN(orderData.order_detail.baggage) ||
      orderData.order_detail.baggage === '' ||
      !orderData.airport_transfer_package_id ||
      deliveryLocation.name === '' ||
      returnLocation.name === '' ||
      deliveryLocationDetail === '' ||
      returnLocationDetail === ''
    ) {
      return showToast({ type: 'warning', message: 'Harap Mengisi Semua Field!' });
    }

    if (orderData.order_status === 'PAID' && !selectedCar?.vehicle_id) {
      return showToast({ type: 'warning', message: 'Harap Mengisi Semua Field!' });
    }

    const utcDifference = moment
      .tz(`${orderData.order_detail.start_booking_date}`, orderData.order_detail.loc_time_id)
      .format('Z')
      .split(':')[0];

    const convertStartBookingTimeUTC = subHours(
      parseISO(`${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`),
      parseInt(utcDifference),
    );

    const selectedPackage = vehicleAirportPackageData?.packages?.find(
      (item) => item.id === orderData.airport_transfer_package_id,
    );

    const priceDifference = orderData.total_amount_order - summaryData.total_payment;

    const payload = {
      reference_id: orderData.id,
      reference_type: 'order',
      approval_setting_id: approvalData.data[0].id,
      data: {
        ...orderData,
        disbursement: {
          ...orderData.disbursement,
          payment_method_id: 0,
          ppn_internal_percentage: 0,
          disbursement_confirmation_image: '',
          payment_platform_fee_type_fixed: 0,
          total_amount_customer_service_fee:
            orderData.total_amount_order === summaryData.total_payment
              ? orderData.disbursement.total_amount_customer_service_fee
              : 0,
          total_amount_external_payment_fee:
            orderData.total_amount_order === summaryData.total_payment
              ? orderData.disbursement.total_amount_external_payment_fee
              : 0,
          payment_platform_fee_type_percentage: 0,
          total_amount_with_customer_service_fee:
            orderData.total_amount_order === summaryData.total_payment || priceDifference > 0
              ? orderData.disbursement.total_amount_with_customer_service_fee
              : 0,
          total_net_amount:
            orderData.total_amount_order === summaryData.total_payment ? orderData.disbursement.total_net_amount : 0,
        },
        airport_package_vehicle_type: `${selectedPackage.title} - ${selectedCar.vehicle_name}`,
        booking_price: summaryData.booking_price,
        total_payment:
          orderData.total_amount_order === summaryData.total_payment
            ? getTotalPrice(orderData?.total_payment, orderData?.point, orderData?.vouchers)
            : getTotalPrice(summaryData?.total_payment, orderData?.point, orderData?.vouchers),
        deposit_e_toll: summaryData.deposit_e_toll,
        exceed_passenger_price: summaryData.exced_max_passenger_charge,
        over_time_price: summaryData.over_time_price,
        over_time: summaryData.over_time_hour,
        rental_delivery_fee: summaryData.rental_delivery_fee,
        rental_return_fee: summaryData.rental_return_fee,
        price_per_day: summaryData.price_per_day,
        deposit: summaryData.deposit,
        outside_operational_charge: summaryData.outside_operational_charge,
        one_day_order_charge: summaryData.one_day_order_charge,
        remainder: orderData.type === 'FULL' ? 0 : summaryData.remainder,
        down_payment: orderData.type === 'FULL' ? 0 : summaryData.total_dp,
        total_amount_order:
          orderData.total_amount_order === summaryData.total_payment
            ? orderData.total_amount_order
            : summaryData.total_payment,
        total_diff_price_with_approval: 0,
        order_airport_package: {
          max_passenger: selectedCar.max_passenger,
          max_suitcase: selectedCar.max_suitecase,
          price: summaryData.booking_price,
          title: selectedPackage.title,
          transmission: selectedCar.transmission,
          vehicle_id: selectedCar.vehicle_id,
          vehicle_name: selectedCar.vehicle_name,
        },
        currency: locationData?.currency ? locationData.currency : orderData.currency,
        order_detail: {
          ...orderData.order_detail,
          vehicle_id: selectedCar.vehicle_id,
          start_booking_date: format(convertStartBookingTimeUTC, 'yyyy-MM-dd'),
          start_booking_time: format(convertStartBookingTimeUTC, 'HH:mm'),
          end_booking_date: format(convertStartBookingTimeUTC, 'yyyy-MM-dd'),
          end_booking_time: format(convertStartBookingTimeUTC, 'HH:mm'),
          location_id: locationData?.id ? locationData.id : orderData.order_detail.location_id,
          loc_time_id: locationData?.time_zone_identifier
            ? locationData.time_zone_identifier
            : orderData.order_detail.loc_time_id,
          rental_return_location_detail: returnLocationDetail,
          rental_delivery_location_detail: deliveryLocationDetail,
          rental_delivery_location: deliveryLocation.name,
          rental_return_location: returnLocation.name,
          flight_number: flightNumber,
          landing_time: orderData.order_detail.landing_time,
        },
        // order_additional_fees: additionalList,
      },
    };
    setShowConfirmation({
      message: 'Apakah anda yakin ingin melanjutkan proses recalculate?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(updateApprovalOrder(payload)).unwrap();
          showToast({ type: 'success', message: 'Approval recalculate berhasil dilakukan' });
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: 'Approval recalculate gagal dilakukan' });
        }
      },
    });
  };

  useEffect(() => {
    if (!checkEmptyObject(data)) {
      setDeliveryLocationDetail(data.order_detail.rental_delivery_location_detail || '-');
      setReturnLocationDetail(data.order_detail.rental_return_location_detail || '-');
      setFlightNumber(data.order_detail?.flight_number || '');
      setStartRentDate(parseISO(data.order_detail.start_booking_date));
      setOrderData({
        ...data,
        order_detail: {
          ...data.order_detail,
          start_booking_time: data.order_detail.start_booking_time.slice(0, 5),
          end_booking_time: data.order_detail.end_booking_time.slice(0, 5),
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (checkEmptyObject(data) || checkEmptyObject(dataZone) || checkEmptyObject(dataAirport) || isPageLoaded) return;
    let getDeliveryLocation;
    let getReturnLocation;

    getDeliveryLocation = dataZone?.shuttle?.find((item) => item.name === data.order_detail?.rental_delivery_location);
    getReturnLocation = dataAirport?.shuttle?.find((item) => item.name === data.order_detail?.rental_return_location);

    if (getDeliveryLocation === undefined && getReturnLocation === undefined) {
      if (getDeliveryLocation === undefined) {
        getDeliveryLocation = dataAirport?.shuttle?.find(
          (item) => item.name === data.order_detail?.rental_delivery_location,
        );
      }
      if (getReturnLocation === undefined) {
        getReturnLocation = dataZone?.shuttle?.find((item) => item.name === data.order_detail?.rental_return_location);
      }
      setIsSwitch(true);
      setDeliveryLocation(getDeliveryLocation ? getDeliveryLocation : { id: 0, name: '' });
      setReturnLocation(getReturnLocation ? getReturnLocation : { id: 0, name: '' });
    } else {
      setIsSwitch(false);
      setDeliveryLocation(getDeliveryLocation ? getDeliveryLocation : { id: 0, name: '' });
      setReturnLocation(getReturnLocation ? getReturnLocation : { id: 0, name: '' });
    }
  }, [data, dataZone, dataAirport, isPageLoaded]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    const getLocation = rentalLocationData?.find((item) => item.id === data.order_detail?.location_id);
    setLocationData(getLocation ? getLocation : { id: 0, name: '', loc_time_id: '', location_id: 0 });
  }, [data, rentalLocationData]);

  useEffect(() => {
    if (!isPageLoaded) return;
    if (
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      !orderData.airport_transfer_package_id ||
      !rentalServicesData.length
    )
      return;
    const subServiceAirportTransfer = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Airport Transfer');

    dispatch(
      fetchSummaryOrder({
        airport_transfer_package_id: orderData?.airport_transfer_package_id,
        start_booking_date: orderData?.order_detail.start_booking_date,
        start_booking_time: orderData?.order_detail.start_booking_time,
        order_type_id: 2,
        location_id: orderData?.order_detail.location_id,
        sub_services_id: subServiceAirportTransfer?.id,
        order_id: orderData.id,
        // order_additional_fees: additionalList,
        // violations: violationPrice,
      }),
    );
  }, [
    orderData,
    rentalServicesData,
    isPageLoaded,
    // additionalList,
    // violationPrice
  ]);

  return (
    <div className="detail-order-airport">
      <div className="detail-order-airport__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper icon={<AirportIcon fill="#009EF7" width="25px" height="25px" />} title="Airport Transfer">
        <div className="detail-order-airport__renter-detail">
          <div className="detail-order-airport__renter-detail-title">
            <h1>Detail Penyewa</h1>
          </div>
          <div className="detail-order-airport__renter-detail-body">
            <InputField label="Nama Lengkap" disabled value={data?.user_name || '-'} />
            <InputField label="Email" disabled value={data?.email || '-'} />

            <div className="detail-order-airport__renter-detail-body__phone-number">
              <InputField
                label="No Handphone"
                disabled
                value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                icon={
                  <img
                    src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                    width={26}
                    height={16}
                    alt={selectedCountry?.name + ' nation flag'}
                  />
                }
              />
              <InputField value={data?.phone_number || '-'} disabled />
            </div>

            <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

            <div className="detail-order-airport__renter-detail-body__phone-number">
              <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                <InputField
                  label="Whatsapp"
                  disabled
                  value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                  icon={
                    <img
                      src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                      width={26}
                      height={16}
                      alt={selectedCountry?.name + ' nation flag'}
                    />
                  }
                />
                <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
              </div>
              <InputField disabled value={data?.wa_number || '-'} />
            </div>
          </div>
        </div>

        <div className="detail-order-airport__rent-detail">
          <div className="detail-order-airport__rent-detail-title">
            <h1>Detail Order</h1>
          </div>
          <div className="detail-order-airport__rent-detail-body">
            <SelectFieldShuttle
              label="Lokasi Rental"
              htmlFor="lokasi-rental"
              data={rentalLocationData}
              value={locationData?.id}
              onChange={rentalLocationHandler}
              placeholder="Lokasi Rental"
              searchFeature
            />
            <div />

            <div className="airport-transfer-form__form__shuttle">
              {/* DELIVERY LOCATION */}
              <SelectFieldShuttle
                label="Lokasi Penjemputan"
                htmlFor="lokasi-penjemputan"
                value={deliveryLocation?.id}
                data={isSwitch ? dataAirport?.shuttle : dataZone?.shuttle}
                onChange={(item) => {
                  setDeliveryLocation(item);
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      rental_delivery_location: item.name,
                      airport_name: isSwitch ? item.name : orderData.order_detail.airport_name,
                    },
                  });
                }}
                placeholder="Masukan Lokasi Penjemputan"
                disable={!locationData?.id ? true : false}
                // intersectionAction={isSwitch ? null : intersectionHandler}
                searchFeature
              />

              {/* RETURN LOCATION */}
              <SelectFieldShuttle
                label="Lokasi Pengantaran"
                htmlFor="lokasi-pengantaran"
                value={returnLocation?.id}
                data={isSwitch ? dataZone?.shuttle : dataAirport?.shuttle}
                onChange={(item) => {
                  setReturnLocation(item);
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      rental_return_location: item.name,
                      airport_name: isSwitch ? orderData.order_detail.airport_name : item.name,
                    },
                  });
                }}
                placeholder="Masukan Lokasi Pengantaran"
                disable={!locationData?.id ? true : false}
                // intersectionAction={isSwitch ? intersectionHandler : null}
                searchFeature
              />

              {/* ICON SWITCH */}
              <SwitchIcon className="switch" onClick={switchHandler} />
            </div>

            {/* DETAIL DELIVERY LOCATION */}
            <InputField
              label="Detail Lokasi"
              htmlFor="detail-lokasi-penjemputan"
              placeholder="Tulis detail lokasi"
              value={deliveryLocationDetail}
              onChange={(e) => setDeliveryLocationDetail(e.target.value)}
            />

            {/* DETAIL RETURN LOCATION */}
            <InputField
              label="Detail Lokasi"
              htmlFor="detail-lokasi-pengantaran"
              placeholder="Tulis detail lokasi"
              value={returnLocationDetail}
              onChange={(e) => setReturnLocationDetail(e.target.value)}
            />

            <div className="detail-order__rent-detail-body__rent-date">
              <div className="start-date">
                {/* START DATE */}
                <SelectFieldDate
                  label="Tanggal"
                  htmlFor="tanggal-mulai"
                  name="tanggal-mulai"
                  handleDaySelect={(date) => {
                    if (date) {
                      setStartRentDate(date);
                      setOrderData({
                        ...orderData,
                        order_detail: {
                          ...orderData.order_detail,
                          start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                        },
                        airport_transfer_package_id: 0,
                      });
                      if (orderData.order_detail.vehicle_id !== 256) {
                        setSelectedCar(undefined);
                      }
                    }
                  }}
                  selectedDay={startRentDate}
                  value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
                  placeholder="Pilih Tanggal"
                />
              </div>

              <SelectFieldTime
                label="Jam Jemput"
                htmlFor="jam-mulai"
                placeholder="00:00"
                value={orderData.order_detail.start_booking_time}
                onChange={(hour, minute) => {
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      start_booking_time: `${hour}:${minute ? minute : '00'}`,
                    },
                    airport_transfer_package_id: 0,
                  });
                  if (orderData.order_detail.vehicle_id !== 256) {
                    setSelectedCar(undefined);
                  }
                }}
                disable={startRentDate === '' ? true : false}
                showAllHours={true}
              />
            </div>

            <SelectFieldDropdown
              label="Pilih Package Mobil"
              htmlFor="pilih-package-mobil"
              placeholder="Pilih Package Mobil"
              data={vehicleAirportPackageData?.packages}
              value={orderData?.airport_transfer_package_id}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  airport_transfer_package_id: +e.target.id,
                });
                if (selectedCar.vehicle_id === 256) return;
                setSelectedCar(undefined);
              }}
              disable={
                orderData.order_detail.start_booking_date === '' ||
                orderData.order_detail.start_booking_time === '' ||
                orderData.order_detail.rental_delivery_location === '' ||
                orderData.order_detail.rental_return_location === ''
              }
            />

            <InputField
              label="No. Penerbangan"
              htmlFor="no-penerbangan"
              placeholder="Tulis No. Penerbangan"
              value={flightNumber}
              onChange={(e) => setFlightNumber(e.target.value)}
            />

            <SelectFieldAirportPackage
              label="Pilih Mobil"
              placeholder="Cari Mobil ..."
              id="airport-package"
              data={selectedPackageVechile?.vehicles}
              selectedCar={selectedCar}
              onSelectCar={setSelectedCar}
              disable={
                orderData.order_detail.start_booking_date === '' ||
                orderData.order_detail.start_booking_time === '' ||
                !orderData.airport_transfer_package_id
              }
            />

            <div className="airport-transfer-form__form__baggage-passenger">
              <InputField label="Kapasitas Koper" value={selectedCar?.max_suitecase || 0} disable />
              <InputField
                type="number"
                label="Koper yang dibawa"
                htmlFor="baggage"
                placeholder="Tulis Jumlah Koper"
                value={orderData.order_detail.baggage}
                onChange={(e) =>
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      baggage: isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value),
                    },
                  })
                }
                onWheel={numberInputOnWheelPreventChange}
              />
            </div>
          </div>
        </div>

        <div className="detail-order-airport__price-detail">
          <div className="detail-order-airport__price-detail-title">
            <h1>Rincian Biaya</h1>
          </div>

          <div className="detail-order-airport__price-detail-body">
            <InputField
              label="Biaya Sewa Paket Mobil"
              disabled
              value={setCurrency(summaryData?.booking_price, locationData?.currency) || '-'}
            />
            <InputField
              label="Outside Operational Hour Charge"
              disabled
              value={setCurrency(summaryData?.outside_operational_charge, locationData?.currency) || '-'}
            />
            <InputField
              label="Pembayaran Dengan Point"
              disabled
              value={data?.point}
              icon={<PointsIcon />}
              iconPosition="start"
            />
            <InputField
              label="Promo"
              disabled
              value={`- ${setCurrency(summaryData?.promo_disc, locationData?.currency)}`}
            />
            <InputField
              label="Total Biaya"
              disabled
              value={setCurrency(summaryData?.total_payment, locationData?.currency) || '-'}
            />
          </div>
        </div>

        {/* <div className="detail-order__price-detail-body__input-violation">
          <InputViolation
            violationList={violationList}
            setViolationList={setViolationList}
            totalPrice={violationPrice}
            isOnEdit={true}
            data={[]}
            violationPrice={0}
          />
        </div>

        <div className="detail-order__input-additional">
          <InputAdditionals
            additionalList={additionalList}
            setAdditionalList={setAdditionalList}
            totalPrice={additionalPrice}
            isOnEdit={true}
            data={[]}
            additionalPrice={0}
          />
        </div> */}

        <div className="detail-order__total-price">
          <div className="total-price-wrapper">
            <h3>Total</h3>
            <p>
              {setCurrency(
                getTotalPrice(summaryData?.total_payment, orderData?.point, orderData?.vouchers),
                locationData?.currency,
              )}
            </p>
          </div>
        </div>

        <div className="detail-order__transaction-buttons">
          <Button variant="outline" className="button btn-cancel" width={208} size="sm" onClick={backHandler}>
            Batal
          </Button>

          {checkPermission(offCanvasMenu, currentMenu, 'update') && (
            <Button className="button" width={208} size="sm" onClick={updateHandler}>
              Recalculate
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default EditOrder;
