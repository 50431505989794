import { createSlice } from '@reduxjs/toolkit';
import {
  getAllWithdraw,
  getDetailWithdraw,
  getTransactionHistory,
  approvalWithdraw,
  uploadTransferProofWithdraw,
} from './actions';

const initialState = {
  data: {},
  selected: {},
  transactionHistory: {},
  imageTransfer: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const withdraw = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWithdraw.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllWithdraw.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllWithdraw.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailWithdraw.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getDetailWithdraw.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailWithdraw.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(getTransactionHistory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.transactionHistory = {};
      })
      .addCase(getTransactionHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getTransactionHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactionHistory = action.payload;
      })
      .addCase(approvalWithdraw.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(approvalWithdraw.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(approvalWithdraw.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactionHistory = action.payload;
      })
      .addCase(uploadTransferProofWithdraw.pending, (state) => {
        state.isError = false;
        state.errorMessage = {};
        state.imageTransfer = {};
      })
      .addCase(uploadTransferProofWithdraw.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadTransferProofWithdraw.fulfilled, (state, action) => {
        state.imageTransfer = action.payload;
      });
  },
});

export default withdraw.reducer;
