import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { getDetailDriverTask } from 'features/drivers/actions';
import { getCustomerData } from 'features/user/slice';
import { fetchOrderById } from 'features/orders/actions';
import { fetchCustomerInfo } from 'features/user/actions';
// import { checkEmptyObject } from 'utils/functionality';
import WithoutDriverTask from './withoutDriverTask';
import WithDriverTask from './withDriverTask';
import PreviewImage from 'components/Modals/PreviewImage';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { Button } from 'components/Global';

const DetailDriverTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const customerId = searchParams.get('customer_id');
  const id = searchParams.get('id');

  const { data, status } = useSelector((state) => state.detailOrder);
  const customer = useSelector(getCustomerData);
  const { selected: selectedDriverTaskData, isLoading: loadingSelectedDriverData } = useSelector(
    (state) => state.driverTaskList,
  );

  const { setShowSpinner } = useAppContext();

  const [showImage, setShowImage] = useState({ show: false, image: '' });

  const backHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(fetchOrderById(key));
    dispatch(getDetailDriverTask(id));

    if (customerId) {
      dispatch(fetchCustomerInfo(customerId));
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     !selectedDriverTaskData ||
  //     checkEmptyObject(selectedDriverTaskData) ||
  //     selectedDriverTaskData.status === 'OPEN' ||
  //     !selectedDriverTaskData.driver_id
  //   )
  //     return;

  //   dispatch(getDetailDriver(selectedDriverTaskData.driver_id));
  // }, [selectedDriverTaskData]);

  useEffect(() => {
    if (status === 'loading' || loadingSelectedDriverData) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <>
      <div className="detail-driver-task">
        <header className="detail-driver-task__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>

        {data?.order_detail?.without_driver ? (
          <WithoutDriverTask
            data={data}
            customer={customer}
            setShowImage={setShowImage}
            selectedDriverTaskData={selectedDriverTaskData}
          />
        ) : data?.order_type_id == '2' || data?.order_type_id == '4' ? (
          <WithoutDriverTask
            data={data}
            customer={customer}
            setShowImage={setShowImage}
            selectedDriverTaskData={selectedDriverTaskData}
            airportTransfer
          />
        ) : (
          <WithDriverTask
            data={data}
            customer={customer}
            setShowImage={setShowImage}
            selectedDriverTaskData={selectedDriverTaskData}
          />
        )}

        <div className="action-btn">
          <Button variant="outline" onClick={backHandler} width={192}>
            Kembali
          </Button>
        </div>
      </div>

      {showImage.show &&
        ReactDOM.createPortal(
          <PreviewImage image={showImage.image} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailDriverTask;
