import React from 'react';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { Button, TableWrapper } from 'components/Global';
import TextAreaInput from 'components/Global/TextArea';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const VehiclePhotos = ({ title, photoData, setShowImage, backButton }) => {
  return (
    <TableWrapper title={title}>
      <div className="vehicle-photos">
        {!photoData || checkEmptyObject(photoData) ? (
          <div className="vehicle-photos_empty-data">
            <span>Tugas Belum di Selesaikan Driver</span>
          </div>
        ) : (
          <div className="vehicle-photos_data">
            <span>{photoData.date}</span>
            <div className="photos">
              {photoData?.images?.map((img) => (
                <div className="identity-image" key={img}>
                  <h3>Foto Mobil</h3>
                  <div className="image">
                    <div
                      className="image-wrapper"
                      onClick={() => setShowImage({ show: true, image: IMAGE_BASE_URL + img })}
                    >
                      <img src={IMAGE_BASE_URL + img} alt="sim image" />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <TextAreaInput label="Keterangan" disable value={photoData?.notes} readOnly />

            {photoData.foul && !checkEmptyObject(photoData.foul) && (
              <div className="foul">
                <h3>Jenis Pelanggaran</h3>
                <ul className="foul__lists">
                  {photoData?.foul?.fouls?.map((item) => (
                    <li key={item.id}>
                      <p>{item.title}</p>
                      <p>{setCurrency(item?.fee).replace('IDR', 'Rp')}</p>
                    </li>
                  ))}
                  <li>
                    <p>Total</p>
                    <p>{setCurrency(photoData.foul?.total_fee).replace('IDR', 'Rp')}</p>
                  </li>
                </ul>
              </div>
            )}

            {backButton && (
              <div className="action-button">
                <Button variant="outline">Kembali</Button>
              </div>
            )}
          </div>
        )}
      </div>
    </TableWrapper>
  );
};

export default VehiclePhotos;
