import { createSlice } from '@reduxjs/toolkit';
import { getAllVendorAdmin, getVendorAdminDetail } from './actions';

const initialState = {
  data: {},
  selected: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: {},
};

export const businessPartnerAdmin = createSlice({
  name: 'businessPartnerAdmin',
  initialState,
  reducers: {
    resetSelectedAdmin: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVendorAdmin.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllVendorAdmin.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllVendorAdmin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getVendorAdminDetail.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getVendorAdminDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVendorAdminDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selected = action.payload;
      });
  },
});

export const { resetSelectedAdmin } = businessPartnerAdmin.actions;
export default businessPartnerAdmin.reducer;
