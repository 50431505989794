import clsx from 'clsx';
import { PaginationTable } from 'components/Global';
import ButtonAdd from 'components/Global/ButtonAdd';
import EmptyState from 'components/Global/EmptyState';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import ActionButtons from 'components/Global/Table/ActionButtons';
import StatusButton from 'components/Global/Table/StatusButton';
import { getAllRentalLocation } from 'features/rental-location/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { businessPartnerRentalAirportColumn, businessPartnerRentalDailyColumn } from 'utils/order/order-column';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import { changeOrderStatus, fetchPartnerOrder } from 'features/orders/actions';
import { indonesianDateFormat } from 'utils/helpers';
import { resetDetailData } from 'features/orders/detailOrderSlice';
import Tabs, { TabPane } from 'components/Global/Tabs';
import { useAppContext } from 'components/Context/AppContext';

const BusinessPartnerRental = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setShowConfirmation, showToast } = useAppContext();
  const { data: partnerOrder, status } = useSelector((state) => state.businessPartnerOrder);
  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  const [searchParams, setSearchParams] = useSearchParams();

  const tabActive = searchParams.get('tab-active');

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    if (!tabActive) return;
    dispatch(fetchPartnerOrder({ page: currentPage, orderType: tabActive === 'Daily' ? 3 : 4 }));
  }, [currentPage, tabActive]);

  useEffect(() => {
    if (!tabActive) {
      setSearchParams({ 'tab-active': 'Daily' });
    } else {
      setCurrentPage(1);
      setActiveTab(tabActive);
    }
  }, [tabActive]);

  const customOrderHandler = () => {
    dispatch(resetDetailData());
    if (activeTab === 'Daily') {
      navigate('/business-partner-rental/custom-order?type=Daily');
    } else {
      navigate('/business-partner-rental/custom-order?type=Airport+Transfer');
    }
  };

  const handleDetail = (e) => {
    const orderId = e.target.parentElement.parentElement.dataset['key'];
    const filteredOrderById = partnerOrder.data.filter((item) => item.id === +orderId);
    navigate({
      pathname: `detail/${filteredOrderById[0].transaction_key}`,
      search: createSearchParams({
        type: activeTab,
      }).toString(),
    });
  };

  const handleSuccess = (e) => {
    const orderId = e.target.parentElement.parentElement.dataset['key'];

    const filteredOrderById = partnerOrder.data.filter((item) => item.id === +orderId);

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menyelesaikan order sewa',
      show: true,
      onClick: async () => {
        if (filteredOrderById[0].order_status !== 'COMPLETED') return;

        const payload = {
          prevStatus: 'COMPLETED',
          nextStatus: 'FINISHED',
          transaction_key: filteredOrderById[0].transaction_key,
        };

        try {
          await dispatch(changeOrderStatus(payload)).unwrap();
          showToast({ type: 'success', message: 'Transaksi Berhasil Diselesaikan' });
        } catch (err) {
          showToast({ type: 'error', message: 'Transaksi Gagal Diselesaikan' });
        } finally {
          dispatch(fetchPartnerOrder({ page: currentPage, orderType: tabActive === 'Daily' ? 3 : 4 }));
          setShowConfirmation(false);
        }
      },
    });
  };

  const data = partnerOrder?.data?.map((item) => ({
    id: item.id,
    name: item.user_name,
    car_type: item.partner_vehicle.name || '-',
    start_date: indonesianDateFormat(item.order_detail.start_booking_date),
    end_date: indonesianDateFormat(item.order_detail.end_booking_date),
    start_time: item.order_detail.start_booking_time?.slice(0, 5),
    total_payment: item.total_payment,
    status: item.order_status.toLowerCase(),
    type: item?.type,
    order_type: item?.order_type_id === 3 ? (item?.order_detail?.without_driver ? 'Tanpa Supir' : 'Dengan Supir') : '-',
  }));
  const rows = 10 - data?.length;

  return (
    <div className="business-partner-rental">
      <ButtonAdd onClick={customOrderHandler} label="Tambah Order" />

      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabPane label="Daily" />
        <TabPane label="Airport Transfer" />
      </Tabs>

      <div className="business-partner-rental__wrapper">
        <div className="business-partner-rental__header">
          <div className="business-partner-rental__title">
            <DriverIcon fill="#009EF7" width="25px" height="25px" />
            <h2>Partner Bisnis Rental</h2>
          </div>
          <div className="transaction-without-driver__filter"></div>
        </div>

        {status === 'loading' ? (
          <LoadingSpinner />
        ) : data?.length === 0 && status !== 'loading' ? (
          <EmptyState />
        ) : (
          <>
            <div className={clsx('table')}>
              <table className="table-wrapper">
                <thead className="table-head">
                  <tr>
                    {(activeTab === 'Daily'
                      ? businessPartnerRentalDailyColumn
                      : businessPartnerRentalAirportColumn
                    ).map((item, i) => {
                      return <th key={i}>{item.header}</th>;
                    })}
                    <th className="action-col">Action</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {data?.length > 0 &&
                    data?.map((item, no) => (
                      <tr key={no}>
                        {(activeTab === 'Daily'
                          ? businessPartnerRentalDailyColumn
                          : businessPartnerRentalAirportColumn
                        ).map((col, idx) => {
                          if (col.value === 'no') {
                            return (
                              <td
                                key={idx}
                                data-type="no"
                                className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                              >
                                {pageNumber(currentPage, no + 1)}
                              </td>
                            );
                          } else if (col.value === 'status') {
                            return (
                              <td
                                key={idx}
                                data-type={'status'}
                                className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                              >
                                {<StatusButton status={item[col.value]} />}
                              </td>
                            );
                          } else if (col.value === 'type') {
                            return (
                              <td
                                key={idx}
                                className={`pre-white-space address_details ${
                                  col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                                }`}
                              >
                                {<StatusButton status={item[col.value].toLowerCase()} />}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={idx}
                                data-type={col.value}
                                className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                              >
                                {item[col.value]}
                              </td>
                            );
                          }
                        })}
                        <td className="btn-action" data-type="actionButtons" data-key={!item.id ? item.code : item.id}>
                          <ActionButtons detail={handleDetail} />
                          {item.status === 'completed' && <ActionButtons success={handleSuccess} />}
                        </td>
                      </tr>
                    ))}
                  {rows > 0 &&
                    [...Array(rows)].map((_, idx) => (
                      <tr key={idx}>
                        {(activeTab === 'Daily'
                          ? businessPartnerRentalDailyColumn
                          : businessPartnerRentalAirportColumn
                        ).map((_, idx) => (
                          <td key={idx}></td>
                        ))}
                        <td className="btn-action"></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={partnerOrder?.pagination?.total}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessPartnerRental;
