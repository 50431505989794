import { createSlice } from '@reduxjs/toolkit';
import { fetchPartnerOrder } from './actions';

const initialState = {
  data: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const partnerOrder = createSlice({
  name: 'partnerOrder',
  initialState,
  reducers: {
    // reducers goes here
    // saveVehicleData: (state, action) => {
    //   return { ...state, vehicleList: action.payload };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPartnerOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchPartnerOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

// export const { saveVehicleData } = partnerOrder.actions;

export default partnerOrder.reducer;
