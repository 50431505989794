import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { ReactComponent as BusinessPartnerIcon } from 'icons/business-partner-filled-icon.svg';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import TextAreaEditor from 'components/Global/TextAreaEditor';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewVendorPartner, editVendorPartner, getVendorPartnerDetail } from 'features/business-partner/actions';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { uploadFile } from 'features/orders/actions';
import SelectFieldBanks from 'components/Global/SelectFieldBanks';
import { checkEmptyObject } from 'utils/functionality';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getAllCurrencies } from 'features/banks/actions';
import SelectFieldImage from 'components/Global/SelectFieldImage';

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const STATUS_DATA = [
  {
    id: 1,
    name: 'Aktif',
  },
  {
    id: 2,
    name: 'Non-Aktif',
  },
];

const AddPartnerVendor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mappedCurrencies, setMappedCurrencies] = useState([]);
  const [businessData, setBusinessData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    address: '',
    location_id: 0,
    location_name: '',
    logo: '',
    currencies: '',
    time_zone_identifier: '',
    is_deactivated: '',
  });

  const { selected: vendorData, status } = useSelector((state) => state.businessPartnerVendor);
  const { data: rentalLocation } = useSelector((state) => state.rentalLocation);
  const { data: dataCurrencies } = useSelector((state) => state.currency);

  const { showToast, setShowSpinner } = useAppContext();

  const { id } = useParams();

  const isEdit = id ? true : false;

  const handleUploadImage = async (e) => {
    if (!e.target.files.length) return;

    try {
      const res = await dispatch(uploadFile({ file: e.target.files[0], name: 'logo' })).unwrap();
      setBusinessData((prev) => ({ ...prev, logo: res.logo }));
    } catch (error) {
      showToast({
        type: 'error',
        message: `Gagal Upload Logo Bisnis Partner - ${error.message}`,
      });
    }
  };

  const onRemoveImage = () => {
    setBusinessData((prev) => ({ ...prev, logo: '' }));
  };

  const saveBusinessHandler = async () => {
    if (businessData.name.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi data Nama bisnis!' });
    } else if (businessData.email.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi data Email bisnis!' });
    } else if (!new RegExp(regexEmail).test(businessData.email)) {
      return showToast({ type: 'warning', message: 'Harap mengisi dengan format Email yang benar!' });
    } else if (businessData.phone.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi No. Handphone!' });
    } else if (businessData.address.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi data Alamat!' });
    } else if (!businessData.is_deactivated) {
      return showToast({ type: 'warning', message: 'Harap memilih Status bisnis!' });
    } else if (!businessData.location_id) {
      return showToast({ type: 'warning', message: 'Harap memilih Lokasi bisnis!' });
    } else if (businessData.currencies.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih Currency' });
    } else if (businessData.logo.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap memilih Logo bisnis!' });
    } else if (businessData.description.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi Deskripsi bisnis!' });
    }

    if (businessData.currencies && !mappedCurrencies?.find((item) => item.name === businessData.currencies)) {
      return showToast({ type: 'error', message: 'Harap Memilih Currency Yang Tersedia' });
    }

    const payload = {
      ...businessData,
      is_deactivated: businessData.is_deactivated === 1 ? false : true,
    };

    try {
      if (isEdit) {
        await dispatch(editVendorPartner({ payload: payload, id: id })).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil merubah data partner bisnis!',
        });
      } else {
        await dispatch(addNewVendorPartner(payload)).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil menambah data partner bisnis!',
        });
      }
      navigate(-1);
    } catch (error) {
      if (isEdit) {
        showToast({
          type: 'error',
          message: `Gagal merubah data perusahaan! - ${error.message}`,
        });
        return;
      }
      showToast({
        type: 'error',
        message: `Gagal menambah data perusahaan! - ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getVendorPartnerDetail(id));
  }, [id]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllCurrencies());
  }, []);

  useEffect(() => {
    if (checkEmptyObject(dataCurrencies)) return;
    setMappedCurrencies(() =>
      dataCurrencies.data
        // ?.filter((item) => item.is_enable)
        ?.map((item) => ({
          ...item,
          name: item.symbol,
          value: item.symbol.toLowerCase(),
        })),
    );
  }, [dataCurrencies]);

  useEffect(() => {
    if (checkEmptyObject(vendorData)) return;
    setBusinessData({ ...vendorData, is_deactivated: vendorData.is_deactivated ? 2 : 1 });
  }, [vendorData]);

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <div className="business-detai">
      <button className="business-detail__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <TableWrapper icon={<BusinessPartnerIcon fill="#009EF7" width="25px" height="25px" />} title="Tambah Partner">
        <div className="business-detail__form">
          <h1 className="business-detail__title">
            {id ? (isEdit ? 'Edit Partner' : 'Detail Partner') : 'Tambah Partner'}
          </h1>
          <div className="form__two-column">
            <InputField
              label="Nama"
              placeholder="Masukan Nama"
              value={businessData.name}
              onChange={(e) => setBusinessData((prev) => ({ ...prev, name: e.target.value }))}
            />
            <InputField
              label="Email"
              type="email"
              placeholder="Masukan Email"
              value={businessData.email}
              onChange={(e) => setBusinessData((prev) => ({ ...prev, email: e.target.value }))}
            />
            <InputField
              label="No. Handphone"
              placeholder="Masukan No. Handphone"
              value={businessData.phone}
              onChange={(e) => setBusinessData((prev) => ({ ...prev, phone: e.target.value }))}
            />
            <InputField
              label="Alamat"
              placeholder="Masukan Alamat"
              value={businessData.address}
              onChange={(e) => setBusinessData((prev) => ({ ...prev, address: e.target.value }))}
            />
            <SelectFieldDropdown
              label="Status"
              data={STATUS_DATA}
              placeholder="Pilih Status"
              value={businessData.is_deactivated}
              onChange={(e) => setBusinessData((prev) => ({ ...prev, is_deactivated: +e.target.id }))}
            />
            <SelectFieldShuttle
              label="Lokasi Service"
              htmlFor="lokasi"
              value={businessData.location_id}
              data={rentalLocation}
              onChange={(e) => {
                setBusinessData((prev) => ({
                  ...prev,
                  location_id: e.id,
                  location_name: e.name,
                  time_zone_identifier: e.time_zone_identifier,
                  currencies: e.currency,
                }));
              }}
              disable={id && !isEdit}
              placeholder="Pilih Lokasi"
              searchFeature
            />
            <SelectFieldBanks
              label="Currency"
              htmlFor="currency"
              placeholder="Pilih Currency"
              disable
              data={mappedCurrencies || []}
              value={businessData?.currencies}
            />
            <SelectFieldImage
              label="Logo"
              value={businessData.logo}
              onChange={handleUploadImage}
              onRemove={onRemoveImage}
            />
          </div>

          <div className="input-group__text-editor">
            <h4>Deskripsi</h4>
            <TextAreaEditor
              id="benefit"
              value={businessData.description}
              onChange={(e) => setBusinessData((prev) => ({ ...prev, description: e }))}
            />
          </div>
        </div>

        <div className="business-detail__buttons">
          <Button width={162} onClick={() => navigate(-1)} variant="outline">
            Kembali
          </Button>
          <Button width={162} onClick={saveBusinessHandler}>
            Simpan
          </Button>
        </div>
      </TableWrapper>
    </div>
  );
};

export default AddPartnerVendor;
