import { InputField, TableWrapper } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import { ReactComponent as VehicleLogoIcon } from 'icons/custom-order-filled-icon.svg';

const uploadImageField = [
  {
    id: 'uif1',
    title: 'KTP',
    cheked: false,
  },
  {
    id: 'uif2',
    title: 'SIM',
    checked: false,
  },
];

const DetailPenyewa = ({
  orderData,
  setOrderData,
  numberInputOnWheelPreventChange,
  ktpImageLink,
  setKtpImageLink,
  simImageLink,
  setSimImageLink,
  isDetail,
}) => {
  const uploadImageAction = (file, name, link) => {
    const lowerCaseName = name.toLocaleLowerCase();
    let identityObject;

    if (lowerCaseName === 'ktp') {
      setKtpImageLink(link);

      if (orderData.order_detail.identity === null) {
        identityObject = { [lowerCaseName]: file.file, sim: null };
      } else {
        identityObject = { ...orderData.order_detail.identity, [lowerCaseName]: file.file };
      }
    } else {
      setSimImageLink(link);
      if (orderData.order_detail.identity === null) {
        identityObject = { [lowerCaseName]: file.file, ktp: null };
      } else {
        identityObject = { ...orderData.order_detail.identity, [lowerCaseName]: file.file };
      }
    }

    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        identity: identityObject,
      },
    });
  };

  return (
    <TableWrapper
      icon={<VehicleLogoIcon fill="#009EF7" width="25px" height="25px" />}
      title="Detail Penyewa"
      style={{ marginBottom: 16 }}
    >
      <div className="business-partner-rental-custom-order__wrapper">
        <h2 className="business-partner-rental-custom-order__title">Detail Penyewa</h2>
        <div className="business-partner-rental-custom-order__input-group">
          <InputField
            type="text"
            label="Nama Penyewa"
            htmlFor="nama-penyewa"
            placeholder="Tulis Nama Penyewa"
            value={orderData.user_name}
            onChange={(e) => setOrderData({ ...orderData, user_name: e.target.value })}
            disable={isDetail}
          />
          <InputField
            type="text"
            label="Email"
            htmlFor="email-penyewa"
            placeholder="Masukan Email"
            value={orderData.email}
            onChange={(e) => setOrderData({ ...orderData, email: e.target.value })}
            disable={isDetail}
          />
          <InputTelpField
            label="No Telpon / Whatsapp"
            htmlFor="telp-penyewa"
            value={orderData.wa_number || ''}
            onChange={(e) => setOrderData({ ...orderData, wa_number: e.target.value, phone_number: e.target.value })}
            countryCodeValue={orderData.phone_country_code}
            onSelectCountry={(code) => setOrderData({ ...orderData, phone_country_code: code })}
            onWheel={numberInputOnWheelPreventChange}
            disable={isDetail}
          />

          <div />

          {uploadImageField.map((item) => (
            <div key={item.id} className="business-partner-rental-custom-order__identity">
              <div style={{ marginBottom: 12, justifyContent: 'start' }}>
                <h3 style={{ fontSize: 12 }}>{item.title}</h3>
              </div>
              <UploadImageCustomOrder
                htmlFor={item.title}
                uploadAction={uploadImageAction}
                imageFile={item.title === 'KTP' ? ktpImageLink : simImageLink}
                base64={true}
                disable={isDetail}
              />
            </div>
          ))}
        </div>
      </div>
    </TableWrapper>
  );
};

export default DetailPenyewa;
