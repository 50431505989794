import React from 'react';
import { ReactComponent as PlusCircleblue } from '../../../icons/plus-icon-circle-blue.svg';
import { ReactComponent as XSign } from '../../../icons/x-sign-icon.svg';
import { setCurrency } from 'utils/functionality';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch } from 'react-redux';
import { deleteViolations } from 'features/violations/violationsSlice';

const ViolationsList = ({ data, disable = false, currency = 'IDR' }) => {
  const dispatch = useDispatch();

  // CONTEXT
  const { setShowAddViolationsModal } = useAppContext();

  // FUNCTION TO REMOVE VIOLATION
  const handleRemove = (id) => {
    dispatch(deleteViolations(id));
  };

  return (
    <div className="violations">
      {/* TITLE */}
      <div className="violations__head">
        <h3 className="violations__head__title">Jenis Pelanggaran</h3>
        {disable ? null : (
          <div className="violations__head__add-violations" onClick={() => setShowAddViolationsModal(true)}>
            <PlusCircleblue />
            <span>Tambah Denda</span>
          </div>
        )}
      </div>

      {/* LIST */}
      <div className="violations__lists">
        {!data || data.length === 0 ? (
          <span>Tidak ada denda</span>
        ) : (
          <ul>
            {data.map((item) => (
              <li key={item.id}>
                <div>{item.violation}</div>
                <div>
                  <p>{setCurrency(item.cost, currency)}</p>
                  <XSign
                    className={disable ? 'delete-sign disable' : 'delete-sign'}
                    onClick={() => handleRemove(item.id)}
                  />
                </div>
              </li>
            ))}
            {data.length !== 0 && (
              <li>
                <div style={{ fontWeight: 700 }}>Total</div>
                <div>
                  <p style={{ fontWeight: 700 }}>
                    {setCurrency(
                      data.reduce((acc, cur) => acc + cur.cost, 0),
                      currency,
                    )}
                  </p>
                  <XSign style={{ opacity: 0 }} />
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ViolationsList;
