import React from 'react';
import ReactDOM from 'react-dom';
import { useState } from 'react';
import DetailOrder from './detail-order';
import BankTransferConfirmation from './BankTransferConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import PreviewImage from 'components/Modals/PreviewImage';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchCustomerInfo } from 'features/user/actions';
import { getAllCouriers } from 'features/couriers/actions';
import EditOrder from './edit-order';

const DetailOrderComponent = () => {
  const dispatch = useDispatch();
  const [showBankTransferConfirmation, setShowBankTransferConfirmation] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const orderData = useSelector(getDetailOrder);

  const { transactionKey } = useParams();
  const [searchParams] = useSearchParams();
  const isUpdating = searchParams.get('update');

  /** Get Filename from given url path */
  const getFileName = (path = '') => (typeof path === 'string' ? path?.split('/')?.pop() : '-');

  /** Image Previewer */
  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  useEffect(() => {
    if (transactionKey) {
      dispatch(fetchCustomerInfo(searchParams.get('customerId')));
      dispatch(getAllCouriers(transactionKey));
    }
  }, [transactionKey]);

  useEffect(() => {
    dispatch(getAllCouriers());
  }, []);

  return (
    <>
      {showBankTransferConfirmation ? (
        <BankTransferConfirmation
          getFileName={getFileName}
          imagePreviewHandler={imagePreviewHandler}
          orderData={orderData}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          setIsPaymentValid={setIsPaymentValid}
          isPaymentValid={isPaymentValid}
        />
      ) : isUpdating ? (
        <EditOrder transactionKey={transactionKey} />
      ) : (
        <DetailOrder
          getFileName={getFileName}
          imagePreviewHandler={imagePreviewHandler}
          orderData={orderData}
          isPaymentValid={isPaymentValid}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          transactionKey={transactionKey}
        />
      )}
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailOrderComponent;
