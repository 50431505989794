import React from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as SchedulePriceFilledIcon } from 'icons/schedule-price-filled-icon.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteSchedulePrice, getAllSchedulePrice } from 'features/schedule-price/actions';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { format } from 'date-fns';
import { indonesianDateFormat } from 'utils/helpers';
import { useAppContext } from 'components/Context/AppContext';
import { resetSelectedSchedulePrice } from 'features/schedule-price/schedulePriceSlice';
import EmptyState from 'components/Global/EmptyState';
import { getAirportPackageById } from 'features/orders/actions';
import ButtonAdd from 'components/Global/ButtonAdd';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Periode Mulai', value: 'start_time' },
  { header: 'Periode Selesai', value: 'end_time' },
  { header: 'Jenis Package', value: 'schedule_price_vehicles' },
  { header: 'Lokasi Sewa', value: 'schedule_price_locations' },
];

const AirportTransferPriceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  const {
    data: schedulePriceData,
    isLoading: loadingSchedulePrice,
    isError: errorSchedulePrice,
  } = useSelector((state) => state.schedulePrice);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  const [currentPage, setCurrentPage] = useState(1);

  const [schedulePriceList, setSchedulePriceList] = useState([]);

  const [packages, setPackages] = useState([]);

  // FUNCTION TO EDIT SCHEDULE PRICE
  const handleEdit = (e) => {
    const schedulePriceId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetSelectedSchedulePrice());
    navigate(`/schedule-price-airport/edit-schedule-price-at?id=${schedulePriceId}`);
  };

  // FUNTION TO DELETE SCHEDULE PRICE
  const handleDelete = (e) => {
    const schedulePriceId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus schedule price',
      show: true,
      onClick: async () => {
        try {
          const res = await dispatch(deleteSchedulePrice(schedulePriceId));

          if (res.meta?.requestStatus === 'fulfilled') {
            showToast({ type: 'success', message: 'Berhasil Hapus schedule price' });
            setSchedulePriceList([]);
            dispatch(getAllSchedulePrice({ page: currentPage, type: 2 }));
            return;
          }
          showToast({ type: 'error', message: 'Gagal Hapus schedule price' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Terjadi Kesalahan' });
        }
      },
    });
  };

  // GET SCHEDULE PRICE DEPENDING ON THE PAGE
  useEffect(() => {
    dispatch(getAllSchedulePrice({ page: currentPage, type: 2 }));
    dispatch(getAllRentalLocation());
  }, [currentPage]);

  // GET ALL SCHEDULE PRICE PACKAGE ID
  useEffect(() => {
    if (Object.keys(schedulePriceData).length === 0) return;
    if (!schedulePriceData.schedule_price) return;

    const packageIds = [];
    schedulePriceData.schedule_price.forEach((item) => {
      item.airport_packages.forEach((pkg) => {
        packageIds.push(pkg.airport_package_id);
      });
    });

    const fetchPackages = async () => {
      const promises = [...new Set(packageIds)].map((packageId) =>
        dispatch(getAirportPackageById({ id: packageId })).unwrap(),
      );

      if (promises) {
        const results = await Promise.allSettled(promises);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((vehicle) => vehicle.value);

        setPackages(fulfilledResults);
        return;
      }

      setPackages([]);
    };

    fetchPackages();
  }, [schedulePriceData]);

  // GENERATE THE TABLE DATA
  useEffect(() => {
    if (packages.length === 0) return;
    if (Object.keys(schedulePriceData).length === 0) return;
    if (!schedulePriceData.schedule_price) return;
    if (Object.keys(rentalLocationData).length === 0) return;

    let newSchedulePriceData = [];

    for (let childSchedule of schedulePriceData.schedule_price) {
      let vehiclesName = [];
      let locationsName = [];

      for (let childLocation of childSchedule.locations) {
        const location = rentalLocationData?.find((item) => item.id === childLocation)?.name;
        locationsName.push(location);
      }

      for (let childPackage of childSchedule.airport_packages) {
        if (vehiclesName.length === 0) {
          const foundedVehicle = packages?.find((item) => item.id === childPackage.airport_package_id);
          vehiclesName.push({ name: foundedVehicle?.title, total: 1 });
        } else {
          const foundedVehicle = packages?.find((item) => item.id === childPackage.airport_package_id);
          const existingVehicle = vehiclesName.find((item) => item.name === foundedVehicle?.title);
          if (existingVehicle) {
            existingVehicle.total += 1;
          } else {
            vehiclesName.push({ name: foundedVehicle?.title, total: 1 });
          }
        }
      }

      newSchedulePriceData.push({
        id: childSchedule.id,
        start_time: indonesianDateFormat(format(new Date(childSchedule.start_time), 'yyyy-MM-dd').toString()),
        end_time: indonesianDateFormat(format(new Date(childSchedule.end_time), 'yyyy-MM-dd').toString()),
        schedule_price_vehicles: vehiclesName,
        schedule_price_locations: locationsName,
      });
    }

    setSchedulePriceList(newSchedulePriceData);
  }, [packages, schedulePriceData, rentalLocationData]);

  const handleAdd = () => {
    navigate(`/schedule-price-airport/add-schedule-price-at`);
  };

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (loadingSchedulePrice) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [loadingSchedulePrice]);

  // SHOW TOAST ERROR WHEN ERROR
  useEffect(() => {
    if (errorSchedulePrice) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [errorSchedulePrice]);

  return (
    <div className="schedule-price">
      <ButtonAdd onClick={handleAdd} label="Tambah Schedule Price Airport" />
      {
        // RETURN EMPTY STATE
        !Object.keys(schedulePriceData).length || !schedulePriceList.length ? (
          <EmptyState />
        ) : (
          <TableWrapper
            icon={<SchedulePriceFilledIcon fill="#009EF7" width="25px" height="25px" />}
            title="Airport Transfer Price"
          >
            <Table
              column={column}
              data={schedulePriceList || null}
              actionBtn
              onEdit={handleEdit}
              onDel={handleDelete}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={schedulePriceData?.pagination?.total_data}
            />
          </TableWrapper>
        )
      }
    </div>
  );
};

export default AirportTransferPriceList;
