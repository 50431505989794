import React from 'react';
import { ReactComponent as SwitchIcon } from '../../../../../icons/switch-icon.svg';
import { InputField } from 'components/Global';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import { format } from 'date-fns';
import SelectFieldTime from 'components/Global/SelectFieldTime';

const AirportTransferForm = ({
  orderData,
  setOrderData,
  startRentDate,
  setStartRentDate,
  setEndRentDate,
  isDetail,
}) => {
  const switchHandler = () => {
    if (isDetail) return;
    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        rental_delivery_location: orderData.order_detail.rental_return_location,
        rental_return_location: orderData.order_detail.rental_delivery_location,
        rental_delivery_location_detail: orderData.order_detail.rental_return_location_detail,
        rental_return_location_detail: orderData.order_detail.rental_delivery_location_detail,
      },
    });
  };

  return (
    <div className="business-partner-rental-custom-order__detail-sewa__input-group">
      <div className="business-partner-rental-custom-order__airport-transfer-form__form">
        <div className="business-partner-rental-custom-order__airport-transfer-form__form__shuttle">
          {/* DELIVERY LOCATION */}
          <InputField
            label="Lokasi Penjemputan"
            htmlFor="lokasi-penjemputan"
            value={orderData.order_detail.rental_delivery_location}
            onChange={(e) => {
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, rental_delivery_location: e.target.value },
              });
            }}
            placeholder="Masukan Lokasi Penjemputan"
            disable={isDetail}
          />

          {/* RETURN LOCATION */}
          <InputField
            label="Lokasi Pengantaran"
            htmlFor="lokasi-pengantaran"
            value={orderData.order_detail.rental_return_location}
            onChange={(e) => {
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, rental_return_location: e.target.value },
              });
            }}
            placeholder="Masukan Lokasi Pengantaran"
            disable={isDetail}
          />

          {/* ICON SWITCH */}
          <SwitchIcon className="switch" onClick={switchHandler} />
        </div>

        {/* DETAIL DELIVERY LOCATION */}
        <InputField
          label="Detail Lokasi Penjemputan"
          htmlFor="detail-lokasi-penjemputan"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_delivery_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
            })
          }
          disable={isDetail}
        />
        {/* DETAIL RETURN LOCATION */}
        <InputField
          label="Detail Lokasi Pengantaran"
          htmlFor="detail-lokasi-pengantaran"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_return_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_return_location_detail: e.target.value },
            })
          }
          disable={isDetail}
        />

        <div className="business-partner-rental-custom-order__airport-transfer-form__form__baggage-passenger">
          <div className="start-date">
            {/* START DATE */}
            <SelectFieldDate
              label="Tanggal"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              handleDaySelect={(date) => {
                if (date) {
                  setStartRentDate(date);
                  setEndRentDate('');
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                      end_booking_date: format(date, 'yyyy-MM-dd').toString(),
                    },
                  });
                }
              }}
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable={isDetail}
            />
          </div>

          {/* START TIME */}
          <SelectFieldTime
            label="Jam Jemput"
            htmlFor="jam-mulai"
            placeholder="00:00"
            value={orderData.order_detail.start_booking_time}
            onChange={(hour, minute) => {
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_time: `${hour}:${minute ? minute : '00'}`,
                  end_booking_time: `${hour}:${minute ? minute : '00'}`,
                },
              });
            }}
            disable={isDetail ? isDetail : startRentDate === '' ? true : false}
            showAllHours={true}
          />
        </div>

        {/* FLIGTH NUMBER */}
        <InputField
          label="No. Penerbangan"
          htmlFor="no-penerbangan"
          placeholder="Tulis No. Penerbangan"
          value={orderData.order_detail.flight_number}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, flight_number: e.target.value },
            })
          }
          disable={isDetail}
        />
      </div>
    </div>
  );
};

export default AirportTransferForm;
