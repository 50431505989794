import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as WithDriverIcon } from 'icons/with-driver-filled-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BookingZone, Button, InputField, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerData } from 'features/user/slice';
import countryCodes from 'utils/country-codes.json';
import ActionButtons from './action-buttons';
import clsx from 'clsx';
import DetailPenyewa from './detail-penyewa';
import DetailSewa from './detail-sewa';
import DetailDeposit from './detail-deposit';
import RincianBiaya from './rincian-biaya';
import Pembayaran from './pembayaran';
import { useAppContext } from 'components/Context/AppContext';
import {
  changeOrderStatus,
  changePaymentType,
  fetchOrderByIdWithApproval,
  getOrderAddOnsById,
} from 'features/orders/actions';
import { getDetailAccountBank } from 'features/banks/actions';
import { getCourierDetail } from 'features/couriers/actions';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import { ReactComponent as ChevronDown } from '../../../../../icons/chevron-down.svg';
import { getTaskDetail } from 'features/driver-task/actions';
import DriverTaskDetail from './driver-task';
import DetailVoucher from './detail-voucher';
import { checkEmptyObject } from 'utils/functionality';
import Tabs, { TabPane } from 'components/Global/Tabs';
import InputViolation from 'components/Global/InputViolation';
import InputAdditionals from 'components/Global/InputAdditionals';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import ReactDOM from 'react-dom';
import PriceDifferenceModal from 'components/Modals/PriceDifferenceModal';
import { getPaymentMethods } from 'features/payment/actions';
import AddOnsField from 'components/Global/AddOnsField';
import { updateApprovalStatus } from 'features/approvals/actions';

const DetailOrder = ({
  orderData: data,
  getFileName,
  imagePreviewHandler,
  isPaymentValid,
  setShowBankTransferConfirmation,
  transactionKey,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const { setShowConfirmation, showToast, setShowAssignDriver, showBroadcastTask, setShowBroadcastTask } =
    useAppContext();

  const customer = useSelector(getCustomerData);
  const customerAccountBank = useSelector((state) => state.banks?.detailAccountBank ?? {});
  const driverTask = useSelector((state) => state.driverTask.selectedTask);
  const couriersData = useSelector((state) => state.couriers.selectedCourier);
  const paymentMethod = useSelector((state) => state.payment.data);
  const addOnsOrder = useSelector((state) => state.detailOrder.addOns);

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [activeTab, setActiveTab] = useState('Data Sekarang');
  // ADDITIONAL STATE
  const [additionalList, setAdditionalList] = useState([]);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  // VIOLIATION STATE
  const [violationList, setViolationList] = useState([]);
  const [violationPrice, setViolationPrice] = useState(0);
  const [oldOrderData, setOldOrderData] = useState({});
  const [newOrderData, setNewOrderData] = useState({});
  const [showPriceDifference, setShowPriceDifference] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(0);

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);
  const customerId = searchParams.get('customerId');

  const showBroadcastTaskHandler = () => {
    setShowBroadcastTask(true);
  };

  const backHandler = () => {
    navigate(-1);
  };

  const handleProcess = () => {
    let prevStatus;
    let nextStatus;
    let confirmationMessage;
    let toastSuccessMessage;
    let toastErrorMessage;

    switch (data?.order_status) {
      case 'CHECKOUT':
        prevStatus = 'CHECKOUT';
        nextStatus = 'PAID';
        confirmationMessage = 'Apakah anda yakin ingin mengkonfirmasi transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Dikonfirmasi';
        toastErrorMessage = 'Transaksi Gagal Dikonfirmasi!';
        break;
      case 'PAID':
        prevStatus = 'PAID';
        nextStatus = 'COMPLETED';
        confirmationMessage =
          data.type === 'HALF'
            ? `Orderan ini menggunakan pembayaran DP ${
                data?.order_detail?.dp_rule?.formula_percentage?.value
                  ? `${data.order_detail.dp_rule.formula_percentage?.value}%`
                  : ''
              }. Sebelum melanjutkan transaksi, pastikan pelanggan telah membayar sisa DP terlebih dahulu. Tetap lanjutkan?`
            : 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      case 'REVIEWING_IDENTITY':
        prevStatus = 'REVIEWING_IDENTITY';
        nextStatus = 'COMPLETED';
        confirmationMessage =
          data.type === 'HALF'
            ? `Orderan ini menggunakan pembayaran DP ${
                data?.order_detail?.dp_rule?.formula_percentage?.value
                  ? `${data.order_detail.dp_rule.formula_percentage?.value}%`
                  : ''
              }. Sebelum melanjutkan transaksi, pastikan pelanggan telah membayar sisa DP terlebih dahulu. Tetap lanjutkan?`
            : 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      default:
        throw new Error('order status not found!');
    }

    const payload = {
      prevStatus,
      nextStatus,
      transaction_key: data?.transaction_key,
    };

    setShowConfirmation({
      message: confirmationMessage,
      show: true,
      onClick: async () => {
        try {
          if (data?.order_approval_status?.includes('UPDATE_ORDER')) {
            await dispatch(updateApprovalStatus({ status: 'approved', id: data?.approval?.id, skip: true })).unwrap();
          } else {
            await dispatch(changeOrderStatus(payload)).unwrap();
          }
          showToast({ type: 'success', message: toastSuccessMessage });

          // change DP 50% to Lunas
          if (data.type === 'HALF' && data.order_status === 'PAID') {
            await dispatch(changePaymentType(data?.transaction_key)).unwrap();
          }
        } catch (err) {
          showToast({ type: 'error', message: toastErrorMessage });
        } finally {
          navigate(-1);
        }
      },
    });
  };

  const RenderedProcessButton = () => {
    const isOrderApproval = data?.approval !== null;
    const isManualTransfer =
      paymentMethod?.find(
        (item) =>
          item.id ===
          (isOrderApproval
            ? data?.approval?.data?.disbursement?.payment_method_id
            : data?.disbursement?.payment_method_id),
      )?.method === 'Manual Transfer';
    const showButtonForConfirmation =
      isManualTransfer &&
      data.order_status === 'CHECKOUT' &&
      data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER';
    const showButtonForUnprocess =
      (data.order_status === 'PAID' || data.order_status === 'REVIEWING_IDENTITY') &&
      !customer?.PersonalInfos?.need_review_sim &&
      !customer?.PersonalInfos?.need_review_ktp &&
      !data.order_approval_status;

    if (showButtonForConfirmation) {
      return (
        <Button
          variant="success"
          className={clsx('button', !isPaymentValid && 'button--disabled')}
          width={208}
          size="sm"
          disabled={!isPaymentValid}
          onClick={handleProcess}
        >
          Proses
        </Button>
      );
    } else if (showButtonForUnprocess) {
      return (
        <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
          Proses
        </Button>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(fetchOrderByIdWithApproval(transactionKey));
    dispatch(getOrderAddOnsById(transactionKey));
    dispatch(getPaymentMethods());
  }, [transactionKey]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setOldOrderData(data || {});
    setNewOrderData(data?.approval?.data || {});
  }, [data]);

  useEffect(() => {
    if (!customerId) return;

    dispatch(getDetailAccountBank(customerId));
  }, [customerId]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  const assignDriverHandler = () => {
    if (selectedDriver) return;
    setShowAssignDriver(true);
  };

  useEffect(() => {
    if (additionalList.length > 0) {
      setAdditionalPrice(() => additionalList.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setAdditionalPrice(0);
  }, [additionalList]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_violations')) {
      if (data.order_violations !== null) {
        setViolationList(data.order_violations);
      }
    }
  }, [data]);

  useEffect(() => {
    if (violationList.length > 0) {
      setViolationPrice(() => violationList?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setViolationPrice(0);
  }, [violationList]);

  // const assignDriverHandler = () => {
  //   if (selectedDriver) return;
  //   setShowAssignDriver(true);
  // };

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'courier_task') === null) return;
    if (!data.courier_task?.user_id) return;
    if (data.order_status === 'COMPLETED' || data.order_status === 'FINISHED') {
      dispatch(getTaskDetail(data?.courier_task?.id));
    }
    dispatch(getCourierDetail(data?.courier_task?.user_id));
    setSelectedDriver(data?.courier_task?.user_id);
  }, [data]);

  useEffect(() => {
    if (!showBroadcastTask) {
      localStorage.removeItem('fee-task');
    }
  }, [showBroadcastTask]);

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  const checkIsDisable = (tasks) => {
    if (!tasks) return;

    if (tasks.length > 0) {
      if (tasks.every((item) => item.string !== 'CANCEL')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="detail-order-with-driver">
      {data?.order_status === 'PAID' && (
        <Button
          className="detail-order__publish-btn"
          style={{
            backgroundColor: checkIsDisable(data?.order_driver_tasks) ? '#e0e0e0' : '#007A05',
          }}
          onClick={showBroadcastTaskHandler}
          disabled={checkIsDisable(data?.order_driver_tasks)}
        >
          Publish Task to Driver
        </Button>
      )}
      <div className="detail-order-with-driver__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper
        icon={<WithDriverIcon fill="#009EF7" width="25px" height="25px" />}
        title="Dengan Supir"
        showExportButton
        onExportToPdf={handleExportToPdf}
        CustomFilterComponent={
          Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
            <div className="btn-export">
              <Button variant="primary" onClick={() => setShowPriceDifference(true)}>
                Detail Perbedaan
              </Button>
            </div>
          ) : null
        }
      >
        {(data?.order_status === 'PAID' || data?.order_status === 'COMPLETED' || data?.order_status === 'FINISHED') &&
          !data.order_approval_status && (
            <div className="detail-order-with-driver__assign-driver">
              <InputField
                icon={<ChevronDown />}
                label="Assign To"
                htmlFor="assign-driver"
                placeholder="Pilih Driver"
                value={couriersData?.name || ''}
                onClick={assignDriverHandler}
                iconPosition="end"
                readOnly
                disable={data?.order_status === 'COMPLETED' || data?.order_status === 'FINISHED'}
                className="assign-driver-input"
              />
            </div>
          )}
        {data?.order_approval_status ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabPane label="Data Sekarang">
              <DetailPenyewa
                data={newOrderData}
                customer={customer}
                selectedCountry={selectedCountry}
                getFileName={getFileName}
                imagePreviewHandler={imagePreviewHandler}
                approvalUpdate
              />
              <DetailSewa data={newOrderData} vehicleById={newOrderData?.order_detail?.vehicle} />

              <div className="detail-order-with-driver__rent-zone">
                <div className="detail-order-with-driver__rent-detail-title">
                  <h1>Zona Penyewaan</h1>
                </div>
                <BookingZone
                  startDate={new Date(newOrderData?.order_detail?.start_booking_date)}
                  durationRentDate={newOrderData.order_detail?.booking_zones?.length}
                  selectedCar={newOrderData?.order_detail?.vehicle}
                  orderData={{ order_detail: { booking_zones: newOrderData?.order_detail?.booking_zones } }}
                  isOrderConfirmation={true}
                  forCustomOrder={false}
                  locTimeId={newOrderData?.order_detail?.loc_time_id}
                  rentalLocationId={newOrderData?.order_detail?.location_id}
                />
              </div>

              <div className="detail-order-with-driver__price-detail-body__input-violation">
                <InputViolation
                  violationList={[]}
                  setViolationList={setViolationList}
                  totalPrice={newOrderData?.order_violations
                    ?.map((item) => item.cost)
                    ?.reduce((acc, cVal) => acc + cVal, 0)}
                  violationPrice={0}
                  data={newOrderData?.order_violations || []}
                  isOnEdit={false}
                />
              </div>
              <div className="detail-order-with-driver__price-detail-body__input-additional">
                <InputAdditionals
                  additionalList={[]}
                  setAdditionalList={setAdditionalList}
                  totalPrice={newOrderData?.order_additional_fees
                    ?.map((item) => item.value)
                    ?.reduce((acc, cVal) => acc + cVal, 0)}
                  additionalPrice={0}
                  data={newOrderData?.order_additional_fees || []}
                  isOnEdit={false}
                />
              </div>

              <DetailDeposit customerAccountBank={customerAccountBank} />
              <AddOnsField data={newOrderData?.addons} totalPrice={newOrderData?.addon_total_price} />
              <RincianBiaya data={newOrderData} isApproval={true} />
              <DetailVoucher data={newOrderData} />
              <Pembayaran
                data={data}
                getFileName={getFileName}
                customer={customer}
                imagePreviewHandler={imagePreviewHandler}
                isPaymentValid={isPaymentValid}
                setShowBankTransferConfirmation={setShowBankTransferConfirmation}
              />
              <DriverTaskDetail
                data={data}
                driverTask={driverTask}
                getFileName={getFileName}
                imagePreviewHandler={imagePreviewHandler}
              />

              <div className="detail-order-with-driver__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>

                <RenderedProcessButton />
              </div>
            </TabPane>
            <TabPane label="Data Sebelum">
              <DetailPenyewa
                data={oldOrderData}
                customer={customer}
                selectedCountry={selectedCountry}
                getFileName={getFileName}
                imagePreviewHandler={imagePreviewHandler}
                approvalUpdate
              />
              <DetailSewa data={oldOrderData} vehicleById={oldOrderData?.order_detail?.vehicle} />

              <div className="detail-order-with-driver__rent-zone">
                <div className="detail-order-with-driver__rent-detail-title">
                  <h1>Zona Penyewaan</h1>
                </div>
                <BookingZone
                  startDate={new Date(oldOrderData?.order_detail?.start_booking_date)}
                  durationRentDate={oldOrderData?.order_detail?.booking_zones?.length}
                  selectedCar={oldOrderData?.order_detail?.vehicle}
                  orderData={{ order_detail: { booking_zones: oldOrderData?.order_detail?.booking_zones } }}
                  isOrderConfirmation={true}
                  forCustomOrder={false}
                  locTimeId={oldOrderData?.order_detail?.loc_time_id}
                  rentalLocationId={oldOrderData?.order_detail?.location_id}
                />
              </div>

              <div className="detail-order-with-driver__price-detail-body__input-violation">
                <InputViolation
                  violationList={[]}
                  setViolationList={setViolationList}
                  totalPrice={oldOrderData?.order_violations
                    ?.map((item) => item.cost)
                    ?.reduce((acc, cVal) => acc + cVal, 0)}
                  violationPrice={0}
                  data={oldOrderData?.order_violations || []}
                  isOnEdit={false}
                />
              </div>
              <div className="detail-order-with-driver__price-detail-body__input-additional">
                <InputAdditionals
                  additionalList={[]}
                  setAdditionalList={setAdditionalList}
                  totalPrice={oldOrderData?.order_additional_fees
                    ?.map((item) => item.value)
                    ?.reduce((acc, cVal) => acc + cVal, 0)}
                  additionalPrice={0}
                  data={oldOrderData?.order_additional_fees || []}
                  isOnEdit={false}
                />
              </div>

              <DetailDeposit customerAccountBank={customerAccountBank} />
              <AddOnsField
                data={oldOrderData?.addons?.length > 0 ? oldOrderData?.addons : addOnsOrder}
                totalPrice={oldOrderData?.addon_total_price}
              />
              <RincianBiaya data={oldOrderData} isApproval={true} />
              <DetailVoucher data={oldOrderData} />

              <div className="detail-order__payment-detail">
                <div className="detail-order__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                  <div
                    className={clsx(
                      'detail-order__payment-detail-body__payment-method',
                      data?.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                    )}
                  >
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data.disbursement)}
                    />
                  </div>

                  {data?.order_status !== 'CHECKOUT' &&
                  data?.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : data?.disbursement.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <DriverTaskDetail
                data={data}
                driverTask={driverTask}
                getFileName={getFileName}
                imagePreviewHandler={imagePreviewHandler}
              />

              <div className="detail-order-with-driver__transaction-buttons">
                <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                  Kembali
                </Button>
              </div>
            </TabPane>
          </Tabs>
        ) : (
          <>
            <DetailPenyewa
              data={data}
              customer={customer}
              selectedCountry={selectedCountry}
              getFileName={getFileName}
              imagePreviewHandler={imagePreviewHandler}
            />
            <DetailSewa data={data} vehicleById={data?.order_detail?.vehicle} />

            <div className="detail-order-with-driver__rent-zone">
              <div className="detail-order-with-driver__rent-detail-title">
                <h1>Zona Penyewaan</h1>
              </div>
              <BookingZone
                startDate={new Date(data?.order_detail?.start_booking_date)}
                durationRentDate={data?.order_detail?.booking_zones?.length}
                selectedCar={data?.order_detail?.vehicle}
                orderData={{ order_detail: { booking_zones: data?.order_detail?.booking_zones } }}
                isOrderConfirmation={true}
                forCustomOrder={false}
                locTimeId={data?.order_detail?.loc_time_id}
                rentalLocationId={data?.order_detail?.location_id}
              />
            </div>

            <div className="detail-order-with-driver__price-detail-body__input-violation">
              <InputViolation
                violationList={[]}
                setViolationList={setViolationList}
                totalPrice={
                  data?.order_violations?.map((item) => item.cost)?.reduce((acc, cVal) => acc + cVal, 0) ||
                  violationPrice
                }
                violationPrice={0}
                data={data?.order_violations || []}
                isOnEdit={false}
              />
            </div>
            <div className="detail-order-with-driver__price-detail-body__input-additional">
              <InputAdditionals
                additionalList={[]}
                setAdditionalList={setAdditionalList}
                totalPrice={
                  data?.order_additional_fees?.map((item) => item.value)?.reduce((acc, cVal) => acc + cVal, 0) ||
                  additionalPrice
                }
                additionalPrice={0}
                data={data?.order_additional_fees || []}
                isOnEdit={false}
              />
            </div>

            <DetailDeposit customerAccountBank={customerAccountBank} />
            <AddOnsField data={addOnsOrder} totalPrice={data?.addon_total_price} />
            <RincianBiaya data={data} />
            <DetailVoucher data={data} />
            <Pembayaran
              data={data}
              getFileName={getFileName}
              customer={customer}
              imagePreviewHandler={imagePreviewHandler}
              isPaymentValid={isPaymentValid}
              setShowBankTransferConfirmation={setShowBankTransferConfirmation}
            />
            <DriverTaskDetail
              data={data}
              driverTask={driverTask}
              getFileName={getFileName}
              imagePreviewHandler={imagePreviewHandler}
            />

            <ActionButtons data={data} backHandler={backHandler} RenderedProcessButton={RenderedProcessButton} />
          </>
        )}
      </TableWrapper>
      {showPriceDifference &&
        ReactDOM.createPortal(
          <PriceDifferenceModal
            setShowPriceDifference={setShowPriceDifference}
            Icon={WithDriverIcon}
            title="Dengan Supir"
            data={data}
          />,
          document.getElementById('modal'),
        )}
    </div>
  );
};

export default DetailOrder;
