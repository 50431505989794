import clsx from 'clsx';
import { getAllVehicles, getVehiclesByFilter } from 'features/vehicle/action';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Checklist } from '../../../icons/check-icon.svg';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrency } from 'utils/functionality';
import ClickOutside from '../ClickOutside';

const baseUrl = process.env.REACT_APP_IMAGES;

const SelectFieldCar = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  onClick, // when choose dropdown
  onChecked,
  data = [],
  className,
  style,
  placeholder,
  disable,
  selectedCar,
  onSelectCar,
  payloadFilter,
  intersectionAction,
  checkBox = false,
  currency = 'IDR',
  ...rest
}) => {
  const dispatch = useDispatch();
  const resetString = '';

  // GLOBAL STATE
  const { data: dataVehicle } = useSelector((state) => state.vehicle);

  // INSTERSECTION HOOKS
  const { ref, entry } = useInView();

  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // FIRST LOAD STATE
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // SEARCH STATE
  const [isSearch, setIsSearch] = useState(false);

  // FILTER CAR STATE
  const [filteredCar, setFilteredCar] = useState([]);

  // IS LOADING WHEN INTERSECTION ACTION ACTIVE
  const [isLoadingIntersection, setIsLoadingIntersection] = useState(false);

  // IS LOADING WHEN SEARCH VEHICLE ACTIVE
  const [isLoadingSearchVehicle, setIsLoadingSearchVehicle] = useState(false);

  // FUNCTION WHEN CLICK DROPDOWN
  const handleClick = (item) => {
    onClick(item.id, resetString);
    onSelectCar(item);
    setIsSearch(false);
    setIsShowDropdown(false);
  };

  // FUNCTION WHEN BLUR
  const handleBlur = () => {
    setTimeout(() => {
      setIsShowDropdown(false);
      setIsSearch(false);
    }, 200);
  };

  // FUNCTION WHEN CHECK THE CHECKBOX
  const handleCheck = (e) => {
    if (filteredCar.length === 0) return;

    const choosedItem = filteredCar.find((item) => item.id == e.target.id);
    choosedItem.checked = e.target.checked;

    const newFilteredCar = [...filteredCar];
    newFilteredCar[newFilteredCar.indexOf(choosedItem)] = choosedItem;
    setFilteredCar(newFilteredCar);

    onChecked(e.target.id, e.target.checked);
    onSelectCar(choosedItem, e.target.checked);
    setIsSearch(false);
  };

  // WILL CALLED WHEN LAST VEHICLE LIST WAS IN VIEW
  useEffect(() => {
    if (entry) {
      if (entry.isIntersecting) {
        if (value !== '') return;
        if (dataVehicle.pagination.page === dataVehicle.pagination.last_page) return;
        if (!intersectionAction) return;

        setIsLoadingIntersection(true);

        setTimeout(() => {
          intersectionAction();
          setIsLoadingIntersection(false);
        }, 1000);
      }
    }
  }, [entry]);

  // SET ISSEARCH WHEN FIRST LOAD
  useEffect(() => {
    // if firstload and user didnt choose yet
    if (typeof value !== 'number' && isFirstLoad && !selectedCar) {
      setIsSearch(true);
      return;
    }

    // when user has choosed a car before
    if (isFirstLoad && value !== '' && data && data.length !== 0 && selectedCar) {
      setIsSearch(false);
      const car = data.find((item) => item.id == value);
      onSelectCar(car);
      return;
    }
  }, [isFirstLoad]);

  // SET FILTERED CAR WITH ALL CAR WHEN FIRST LOAD
  useEffect(() => {
    if (!data) return;

    if (!selectedCar && !value) {
      setIsSearch(true);
      setIsFirstLoad(true);
    }

    // if check box true
    if (checkBox) {
      if (!selectedCar) {
        const mappingCheckProp = data.map((item) => {
          return { ...item, checked: false };
        });
        setFilteredCar(mappingCheckProp);
      } else {
        const mappingCheckProp = data.map((item) => {
          return { ...item, checked: false };
        });

        for (let i = 0; i < selectedCar.length; i++) {
          for (let j = 0; j < selectedCar[i].cars.length; j++) {
            const checkeditem = mappingCheckProp.find((item) => item.id === selectedCar[i].cars[j].id);
            mappingCheckProp[mappingCheckProp.indexOf(checkeditem)] = {
              ...mappingCheckProp[mappingCheckProp.indexOf(checkeditem)],
              checked: selectedCar[i].cars[j].checked,
            };
          }
        }

        setFilteredCar(mappingCheckProp);
      }
      // if check box false
    } else {
      setFilteredCar(data);
    }
  }, [data, value, selectedCar]);

  // FILTER CAR
  useEffect(() => {
    if ((!isSearch && value !== '' && typeof value === 'number') || isFirstLoad) return;

    if (value === '') {
      if (checkBox) {
        dispatch(getAllVehicles(payloadFilter.page));
        setIsLoadingSearchVehicle(true);
        return;
      }

      dispatch(getVehiclesByFilter(payloadFilter));
      setIsLoadingSearchVehicle(true);
      return;
    }

    // debbounce
    const timeout = setTimeout(() => {
      if (!payloadFilter) return;

      if (checkBox) {
        dispatch(getVehiclesByFilter({ ...payloadFilter, customOrder: false, name: value.toLowerCase() }));
        setIsLoadingSearchVehicle(true);
        return;
      }

      dispatch(getVehiclesByFilter({ ...payloadFilter, name: value.toLowerCase() }));
      setIsLoadingSearchVehicle(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, [value]);

  // SET FILTER CAR AFTER GET VEHICLE BY NAME
  useEffect(() => {
    if (isFirstLoad) return;

    if (checkBox) {
      setIsLoadingSearchVehicle(false);
    } else {
      setIsLoadingSearchVehicle(false);
      setFilteredCar(dataVehicle.vehicles);
    }
  }, [dataVehicle]);

  return (
    <div className={clsx('select-field-car', className)}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-car__label">
          {label}
        </label>
      )}
      <div
        style={{ width: '100%' }}
        className={disable ? 'select-field-car__input-field disable' : 'select-field-car__input-field'}
        tabIndex={!isSearch ? 0 : 1}
        onFocus={
          !isSearch
            ? () => {
                setIsFirstLoad(false);
                setIsSearch(true);
                // onClick('', resetString);
                !checkBox && onClick('', resetString);
              }
            : () => {
                setIsFirstLoad(false);
              }
        }
        onBlur={isSearch ? () => {} : handleBlur}
      >
        {(isFirstLoad && !isSearch && !selectedCar) ||
        (isFirstLoad && isSearch && !selectedCar) ||
        (!isFirstLoad && isSearch && selectedCar) ||
        (!isFirstLoad && isSearch && !selectedCar) ||
        (!isFirstLoad && !isSearch && !selectedCar) ? (
          <input
            autoFocus={isSearch && !isFirstLoad ? true : false}
            id={htmlFor}
            type="text"
            placeholder={placeholder}
            name={name}
            style={style}
            className="select-field-car__input-field__input"
            value={value}
            disabled={disable}
            onChange={onChange}
            onFocus={() => setIsShowDropdown(true)}
            onBlur={
              checkBox
                ? () => {
                    setIsSearch(false);
                  }
                : handleBlur
            }
            {...rest}
          />
        ) : !checkBox ? (
          <div className="select-field-car__input-field__car">
            <div>
              <div>
                {selectedCar?.photo?.length > 0 && (
                  <img src={`${baseUrl}${selectedCar?.photo[0]?.name}`} alt={selectedCar?.name} loading="lazy" />
                )}
              </div>
              <div>
                <h3>{`${selectedCar?.brand_name} ${selectedCar?.name}`}</h3>
                <span>
                  <p>{`${selectedCar?.max_passanger} Seats`}</p>
                  <span />
                  <p>{`${selectedCar?.max_suitcase} Koper`}</p>
                  <span />
                  <p>{`Transmisi ${selectedCar?.transmission}`}</p>
                </span>
              </div>
            </div>
            <span>
              {!payloadFilter?.supportDriver
                ? selectedCar?.slash_price === 0
                  ? setCurrency(selectedCar?.price, currency)
                  : setCurrency(selectedCar?.price - selectedCar?.slash_price, currency)
                : selectedCar?.slash_price === 0
                ? setCurrency(selectedCar?.price_with_driver, currency)
                : setCurrency(selectedCar?.price_with_driver - selectedCar?.slash_price, currency)}
            </span>
          </div>
        ) : (
          <div className="select-field-car__input-field__car-checkbox">
            {selectedCar &&
              selectedCar?.map((item, i) => (
                <span className={'name'} key={Math.random() + i}>
                  <p>{item.cars[0]?.name}</p>
                  {item.total > 1 && <span>{item.total}</span>}
                </span>
              ))}
          </div>
        )}
      </div>

      {/* DROPDOWN */}
      {isShowDropdown && !checkBox && (
        <ul className="select-field-car__dropdown">
          {filteredCar &&
            filteredCar.map((item, i) => (
              <li
                key={item.id}
                className={`list-car ${!item.is_valid_for_order ? 'disable' : ''}`}
                data-id={item.id}
                onClick={() => handleClick(item)}
                ref={
                  i === (filteredCar.length !== 0 && filteredCar ? filteredCar.length - 1 : data.length - 1)
                    ? ref
                    : null
                }
              >
                <div>
                  {item.photo.length !== 0 && (
                    <img src={`${baseUrl}${item.photo[0]?.name}`} alt={item.name} loading="lazy" />
                  )}
                </div>
                <div>
                  <h3>{`${item.brand_name} ${item.name} ${
                    item.minimal_rental_day !== 0 ? `(Min ${item.minimal_rental_day} Hari)` : ''
                  }`}</h3>
                  <span>
                    <p>{`${item.max_passanger} Seats`}</p>
                    <span />
                    <p>{`${item.max_suitcase} Koper`}</p>
                    <span />
                    <p>{`Transmisi ${item.transmission}`}</p>
                  </span>
                  <span>
                    {!payloadFilter?.supportDriver
                      ? item.slash_price === 0
                        ? setCurrency(item.price, currency)
                        : setCurrency(item.price - item.slash_price, currency)
                      : item.slash_price === 0
                      ? setCurrency(item.price_with_driver, currency)
                      : setCurrency(item.price_with_driver - item.slash_price, currency)}
                  </span>
                </div>
              </li>
            ))}
          {isLoadingIntersection && <li>Loading ...</li>}
        </ul>
      )}

      {/* DROPDOWN CHECKBOX */}
      {isShowDropdown && !isLoadingSearchVehicle && checkBox && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <ul className="select-field-car__dropdown">
            {filteredCar &&
              filteredCar.map((item, i) => (
                <li
                  key={item.id}
                  className="list-check"
                  data-id={item.id}
                  ref={
                    i === (filteredCar.length !== 0 && filteredCar ? filteredCar.length - 1 : data.length - 1)
                      ? ref
                      : null
                  }
                >
                  <div>
                    {item.photo.length !== 0 && (
                      <img src={`${baseUrl}${item.photo[0]?.name}`} alt={item.name} loading="lazy" />
                    )}
                  </div>
                  <div>
                    <h3>{`${item.brand_name} ${item.name}`}</h3>
                    <span>
                      <p>{`${item.max_passanger} Seats`}</p>
                      <span />
                      <p>{`${item.max_suitcase} Koper`}</p>
                      <span />
                      <p>{`Transmisi ${item.transmission}`}</p>
                    </span>
                    {/* <span> {setCurrency(item.price, currency)}</span> */}
                  </div>
                  <div className={item.checked ? ' check' : ''}>
                    {item.checked && <Checklist />}
                    <input type="checkbox" id={item.id} checked={item.checked} value={item.id} onChange={handleCheck} />
                  </div>
                </li>
              ))}
            {isLoadingIntersection && <li>Loading ...</li>}
          </ul>
        </ClickOutside>
      )}

      {/* WHEN NO DATA */}
      {isShowDropdown && (!filteredCar || filteredCar?.length === 0) && (
        <ul className="select-field-car__dropdown">
          <li>No Vehicles found</li>
        </ul>
      )}

      {/* WHEN LOADING */}
      {/* {isShowDropdown && isLoadingSearchVehicle && (
        <ul className="select-field-car__dropdown">
          <li>Loading ...</li>
        </ul>
      )} */}
    </div>
  );
};

export default React.memo(SelectFieldCar);
