import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getBusinessPartnerDetail = createAsyncThunk(
  'businessPartner/getBusinessPartnerDetail',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/business-partners/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getAllBusinessPartners = createAsyncThunk(
  'businessPartner/getAllBusinessPartners',
  async (page = 1, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios
        .get(`${BASE_URL}/${API_VERSION}/business-partners?limit=10&page=${page}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const addNewBusinessPartner = createAsyncThunk(
  'businessPartner/addNewBusinessPartner',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(`${BASE_URL}/${API_VERSION}/business-partners`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const editBusinessPartnerById = createAsyncThunk(
  'businessPartner/editBusinessPartnerById',
  async ({ payload, id }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/business-partners/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteBusinessPartnerById = createAsyncThunk(
  'businessPartner/deleteBusinessPartnerById',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.delete(`${BASE_URL}/${API_VERSION}/business-partners/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const uploadBusinessPartnerLogo = createAsyncThunk(
  'businessPartner/uploadBusinessPartnerLogo',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/business-partners/upload`,
        { file: payload },
        {
          headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getPartnerEmployeeDetail = createAsyncThunk(
  'businessPartner/getPartnerEmployeeDetail',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/business-partner-users/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getAllPartnerEmployees = createAsyncThunk(
  'businessPartner/getAllPartnerEmployees',
  async (page = 1, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios
        .get(`${BASE_URL}/${API_VERSION}/business-partner-users?limit=10&page=${page}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const addBusinessPartnerEmployee = createAsyncThunk(
  'businessPartner/addBusinessPartnerEmployee',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(`${BASE_URL}/${API_VERSION}/business-partner-users`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const editBusinessPartnerEmployee = createAsyncThunk(
  'businessPartner/editBusinessPartnerEmployee',
  async ({ payload, id }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/business-partner-users/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteBusinessPartnerEmployee = createAsyncThunk(
  'businessPartner/deleteBusinessPartnerEmployee',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.delete(`${BASE_URL}/${API_VERSION}/business-partner-users/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getAllVendorPartner = createAsyncThunk(
  'businessPartnerVendor/getAllVendorPartner',
  async (page = 1, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios
        .get(`${BASE_URL}/${API_VERSION}/admin/vendor-partnerships?limit=10&page=${page}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getVendorPartnerDetail = createAsyncThunk(
  'businessPartnerVendor/getVendorPartnerDetail',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/vendor-partnerships/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const addNewVendorPartner = createAsyncThunk(
  'businessPartnerVendor/addNewVendorPartner',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/admin/vendor-partnerships`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const editVendorPartner = createAsyncThunk(
  'businessPartnerVendor/editVendorPartner',
  async ({ payload, id }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/admin/vendor-partnerships/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteVendorPartner = createAsyncThunk(
  'businessPartnerVendor/deleteVendorPartner',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/vendor-partnerships/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getAllVendorAdmin = createAsyncThunk(
  'businessPartnerAdmin/getAllVendorAdmin',
  async (page = 1, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios
        .get(`${BASE_URL}/${API_VERSION}/partner/admin-vendor-partnerships?limit=10&page=${page}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getVendorAdminDetail = createAsyncThunk(
  'businessPartnerAdmin/getVendorAdminDetail',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/partner/admin-vendor-partnerships/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const createVendorAdmin = createAsyncThunk(
  'businessPartnerAdmin/createVendorAdmin',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/partner/admin-vendor-partnerships`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const editPartnerAdmin = createAsyncThunk(
  'businessPartnerAdmin/editPartnerAdmin',
  async ({ payload, id }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/partner/admin-vendor-partnerships/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deletePartnerAdmin = createAsyncThunk('businessPartnerAdmin/deletePartnerAdmin', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/partner/admin-vendor-partnerships/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
