import { InputField, TableWrapper, UploadVehicleImage } from 'components/Global';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';

const DetailBisnisPartner = ({ partnerData, setPartnerData, imageFiles, handleRemove, isDetail }) => {
  return (
    <TableWrapper
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
      title="Custom Mobil Order Bisnis Partner"
      style={{ marginBottom: 16 }}
    >
      <div className="business-partner-rental-custom-order__wrapper">
        <h1 className="business-partner-rental-custom-order__title">Detail Penyewa Bisnis Partner</h1>
        <div className="business-partner-rental-custom-order__input-group">
          <InputField
            label="Nama Vehicle"
            value={partnerData.vehicle_name}
            onChange={(e) => setPartnerData({ ...partnerData, vehicle_name: e.target.value })}
            placeholder="Masukan Nama Vehicle"
            className="input-field"
            disable={isDetail}
          />

          <InputField
            label="Plat Nomer"
            value={partnerData.license_plate}
            onChange={(e) => setPartnerData({ ...partnerData, license_plate: e.target.value })}
            placeholder="Masukan Plat Nomer"
            className="input-field"
            disable={isDetail}
          />

          <div>
            <div style={{ marginBottom: 12 }}>
              <h3 style={{ fontSize: 12 }}>Gambar Mobil</h3>
            </div>
            <UploadVehicleImage previewable imageFiles={imageFiles} handleRemove={handleRemove} disable={isDetail} />
          </div>
        </div>
      </div>
    </TableWrapper>
  );
};

export default DetailBisnisPartner;
