import clsx from 'clsx';
import React, { useState } from 'react';
import ClickOutside from '../ClickOutside';
import { useSelector } from 'react-redux';

const SelectFieldDriverCheckbox = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  data = [],
  disabled,
  className,
  style,
  placeholder,
  selectedDriver = '',
  onSelectDriver,
  icon,
  iconPosition = 'end',
}) => {
  const { isLoading } = useSelector((state) => state.drivers);

  const [showDropdown, setShowDropdown] = useState(false);

  const clickDriverHandler = (driver) => {
    onSelectDriver(driver);
    setShowDropdown(false);
  };

  return (
    <div className={clsx('select-driver-checkbox', className)} style={style}>
      <label htmlFor={htmlFor} className="input-title">
        {label}
      </label>
      <div
        className={clsx('select-input-field', showDropdown && 'focus', disabled && 'disabled')}
        placeholder={placeholder}
        onClick={() => setShowDropdown((prev) => !prev)}
      >
        {icon && iconPosition === 'start' && <div className="icon-wrapper">{icon}</div>}
        {showDropdown ? (
          <input
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className="input-value"
            name={name}
            id={htmlFor}
            autoFocus
          />
        ) : (
          <div className="seleceted-drivers">
            {!selectedDriver ? <p>{placeholder}</p> : <div>{selectedDriver?.name}</div>}
          </div>
        )}
        {icon && iconPosition === 'end' && <div className="icon-wrapper">{icon}</div>}
      </div>

      {showDropdown ? (
        <ClickOutside onClickOutside={() => setShowDropdown(false)}>
          <ul className="select-input-field__dropdown">
            {data && data?.length !== 0 ? (
              data.map((item) => (
                <li key={item.id} className="select-input-field__item" onClick={() => clickDriverHandler(item)}>
                  <span>{item.name}</span>
                </li>
              ))
            ) : !isLoading ? (
              <li className="select-input-field__item">No Data found</li>
            ) : (
              <li className="select-input-field__item">Loading ...</li>
            )}
          </ul>
        </ClickOutside>
      ) : null}
    </div>
  );
};

export default SelectFieldDriverCheckbox;
