import { InputField } from 'components/Global';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import { addDays, format, parseISO } from 'date-fns';

const WithoutDriverForm = ({
  orderData,
  setOrderData,
  startRentDate,
  setStartRentDate,
  endRentDate,
  setEndRentDate,
  isDetail,
}) => {
  return (
    <div className="business-partner-rental-custom-order__detail-sewa__input-group">
      <div className="business-partner-rental-custom-order__without-driver-form__form">
        <InputField
          label="Detail Lokasi Pengantaran"
          htmlFor="detail-lokasi-pengantaran"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_delivery_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
            })
          }
          disable={isDetail}
        />

        {/* DETAIL RETURN LOCATION */}
        <InputField
          label="Detail Lokasi Pengembalian"
          htmlFor="detail-lokasi-pengembalian"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_return_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_return_location_detail: e.target.value },
            })
          }
          disable={isDetail}
        />

        {/* START DATE & END DATE */}
        <div>
          <SelectFieldDate
            label="Tanggal Mulai"
            htmlFor="tanggal-mulai"
            name="tanggal-mulai"
            handleDaySelect={(date) => {
              if (date) {
                setStartRentDate(date);
                setEndRentDate('');
                setOrderData({
                  ...orderData,
                  order_detail: {
                    ...orderData.order_detail,
                    start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                  },
                });
              }
            }}
            selectedDay={startRentDate}
            value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
            placeholder="Pilih Tanggal"
            disable={isDetail}
          />

          <SelectFieldDate
            label="Tanggal Selesai"
            htmlFor="tanggal-selesai"
            name="tanggal-selesai"
            handleDaySelect={(date) => {
              if (date) {
                setEndRentDate(date);
                setOrderData({
                  ...orderData,
                  order_detail: {
                    ...orderData.order_detail,
                    end_booking_date: format(date, 'yyyy-MM-dd').toString(),
                  },
                });
              }
            }}
            selectedDay={endRentDate}
            fromDate={addDays(startRentDate === '' ? parseISO(startRentDate) : startRentDate, 1)}
            value={endRentDate !== '' ? format(endRentDate, 'dd-MM-yyyy') : endRentDate}
            placeholder="Pilih Tanggal"
            disable={isDetail ? isDetail : startRentDate === '' ? true : false}
          />
        </div>

        {/* START TIME & END TIME */}
        <div>
          <SelectFieldTime
            label="Jam Mulai"
            htmlFor="jam-mulai"
            placeholder="00:00"
            value={orderData.order_detail.start_booking_time}
            onChange={(hour, minute) => {
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_time: `${hour}:${minute ? minute : '00'}`,
                  end_booking_time: `${hour}:${minute ? minute : '00'}`,
                },
              });
            }}
            disable={isDetail ? isDetail : startRentDate === '' ? true : false}
            showAllHours={true}
          />

          <SelectFieldTime
            label="Jam Selesai"
            htmlFor="jam-selesai"
            placeholder="00:00"
            value={orderData.order_detail.end_booking_time}
            onChange={(hour, minute) => {
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, end_booking_time: `${hour}:${minute ? minute : '00'}` },
              });
            }}
            disable={
              isDetail
                ? isDetail
                : startRentDate === '' || orderData.order_detail.start_booking_time === ''
                ? true
                : false
            }
            startHour={orderData.order_detail.start_booking_time}
            showAllHours={true}
          />
        </div>
      </div>
    </div>
  );
};

export default WithoutDriverForm;
