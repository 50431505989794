import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { ReactComponent as BusinessPartnerIcon } from 'icons/business-partner-filled-icon.svg';
import { ReactComponent as PasswordEyeIcon } from 'icons/password-eye-icon.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editPartnerAdmin, getVendorPartnerDetail } from 'features/business-partner/actions';
import { checkEmptyObject } from 'utils/functionality';

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddPartnerAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [adminData, setAdminData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });

  const { selected: data, status } = useSelector((state) => state.businessPartnerAdmin);

  const { showToast, setShowSpinner } = useAppContext();

  const { id } = useParams();

  const saveAdminHandler = async () => {
    if (adminData.name.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi data Nama bisnis!' });
    } else if (adminData.email.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi data Email bisnis!' });
    } else if (!new RegExp(regexEmail).test(adminData.email)) {
      return showToast({ type: 'warning', message: 'Harap mengisi dengan format Email yang benar!' });
    } else if (adminData.phone.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap mengisi No. Handphone!' });
    }

    if (!id) {
      if (adminData.password.trim() === '') {
        return showToast({ type: 'warning', message: 'Harap mengisi data Password!' });
      }
    }

    try {
      if (id) {
        await dispatch(editPartnerAdmin({ payload: adminData, id: id })).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil merubah data admin!',
        });
      } else {
        await dispatch(AddPartnerAdmin(adminData)).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil menambah data admin!',
        });
      }
      navigate(-1);
    } catch (error) {
      if (id) {
        showToast({
          type: 'error',
          message: `Gagal merubah data perusahaan! - ${error.message}`,
        });
        return;
      }
      showToast({
        type: 'error',
        message: `Gagal menambah data perusahaan! - ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getVendorPartnerDetail(id));
  }, [id]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setAdminData({ ...data });
  }, [data]);

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <div className="business-detail">
      <button className="business-detail__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <TableWrapper
        icon={<BusinessPartnerIcon fill="#009EF7" width="25px" height="25px" />}
        title="Admin Partner Bisnis"
      >
        <div className="business-detail__form">
          <h1 className="business-detail__title">
            {id ? 'Detail Admin Partner Bisnis' : 'Tambah Admin Partner Bisnis'}
          </h1>
          <div className="form__two-column">
            <InputField
              label="Nama"
              placeholder="Masukan Nama"
              value={adminData.name}
              onChange={(e) => setAdminData((prev) => ({ ...prev, name: e.target.value }))}
            />
            <InputField
              label="Email"
              type="email"
              placeholder="Masukan Email"
              value={adminData.email}
              onChange={(e) => setAdminData((prev) => ({ ...prev, email: e.target.value }))}
            />
            <InputField
              label="No. Handphone"
              placeholder="Masukan No. Handphone"
              value={adminData.phone}
              onChange={(e) => setAdminData((prev) => ({ ...prev, phone: e.target.value }))}
            />
            {id ? null : (
              <InputField
                type={showPassword ? 'text' : 'password'}
                label="Password"
                placeholder="Masukan Password"
                value={adminData.password}
                onChange={(e) => setAdminData((prev) => ({ ...prev, password: e.target.value }))}
                readOnly
                onFocus={(e) => e.target.removeAttribute('readonly')}
                onBlur={(e) => e.target.setAttribute('readonly', true)}
                icon={<PasswordEyeIcon />}
                onIconClick={() => setShowPassword((prev) => !prev)}
                iconPosition="end"
              />
            )}
          </div>
        </div>

        <div className="business-detail__buttons">
          <Button width={162} onClick={() => navigate(-1)} variant="outline">
            Kembali
          </Button>
          <Button width={162} onClick={saveAdminHandler}>
            Simpan
          </Button>
        </div>
      </TableWrapper>
    </div>
  );
};

export default AddPartnerAdmin;
