import { InputField, SelectFieldDuration } from 'components/Global';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import React from 'react';
import { addDays, format } from 'date-fns';
import SelectFieldTime from 'components/Global/SelectFieldTime';

const WithDriverForm = ({
  orderData,
  setOrderData,
  startRentDate,
  setStartRentDate,
  durationRent,
  setDurationRent,
  dayDuration,
  setDayDuration,
  isDetail,
}) => {
  const durationRentDateHandler = (duration) => {
    setDurationRent(duration);
    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        end_booking_date: format(addDays(startRentDate, duration - 1), 'yyyy-MM-dd').toString(),
      },
    });

    if (dayDuration.length !== 0) {
      const copyDayDuration = [...dayDuration];
      const slicedCopyDayDuration = copyDayDuration.slice(0, duration);
      setDayDuration(slicedCopyDayDuration);
    }
  };

  return (
    <div className="business-partner-rental-custom-order__detail-sewa__input-group">
      <h2 className="business-partner-rental-custom-order__title" style={{ color: '#009ef7', marginTop: 0 }}>
        Detail Hari
      </h2>
      <div className="business-partner-rental-custom-order__with-driver-form__form">
        {/* TANGGAL MULAI & DURASI */}
        <div className="business-partner-rental-custom-order__with-driver-form__form__date-duration">
          <SelectFieldDate
            label="Tanggal Mulai"
            htmlFor="tanggal-mulai"
            name="tanggal-mulai"
            handleDaySelect={(date) => {
              if (date) {
                setStartRentDate(date);
                setOrderData({
                  ...orderData,
                  order_detail: {
                    ...orderData.order_detail,
                    start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                    end_booking_date: durationRent
                      ? format(addDays(date, durationRent - 1), 'yyyy-MM-dd').toString()
                      : '',
                  },
                });
                setDayDuration((prev) =>
                  prev.map((item, idx) => ({
                    ...item,
                    date: format(addDays(date, idx), 'yyyy-MM-dd').toString(),
                  })),
                );
              }
            }}
            selectedDay={startRentDate}
            value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
            placeholder="Pilih Tanggal"
            disable={isDetail}
          />

          <SelectFieldDuration
            label="Durasi Sewa"
            htmlFor="durasi-sewa"
            placeholder="0 Hari"
            startDate={startRentDate}
            value={durationRent}
            onClick={durationRentDateHandler}
            disable={isDetail ? isDetail : startRentDate ? false : true}
          />
        </div>
        {Array.from({ length: durationRent }, (_, i) => i + 1).map((_, idx) => {
          return (
            <div key={idx} className="business-partner-rental-custom-order__with-driver-form__form__day">
              <InputField
                label={`Detail Lokasi Pengantaran Hari ke-${idx + 1}`}
                placeholder="Tulis detail lokasi"
                value={dayDuration[idx]?.detail_pickup_location || ''}
                onChange={(e) =>
                  setDayDuration((prev) =>
                    prev[idx] === undefined
                      ? [
                          ...prev,
                          {
                            ...prev[idx],
                            detail_pickup_location: e.target.value,
                            date: format(addDays(startRentDate, idx), 'yyyy-MM-dd').toString(),
                          },
                        ]
                      : prev.map((item, day) =>
                          day === idx
                            ? {
                                ...item,
                                detail_pickup_location: e.target.value,
                                date: format(addDays(startRentDate, idx), 'yyyy-MM-dd').toString(),
                              }
                            : item,
                        ),
                  )
                }
                disable={isDetail}
              />
              <InputField
                label={`Detail Lokasi Pengembalian Hari ke-${idx + 1}`}
                placeholder="Tulis detail lokasi"
                value={dayDuration[idx]?.detail_drop_off_location || ''}
                onChange={(e) =>
                  setDayDuration((prev) =>
                    prev[idx] === undefined
                      ? [
                          ...prev,
                          {
                            ...prev[idx],
                            detail_drop_off_location: e.target.value,
                            date: format(addDays(startRentDate, idx), 'yyyy-MM-dd').toString(),
                          },
                        ]
                      : prev.map((item, day) =>
                          day === idx
                            ? {
                                ...item,
                                detail_drop_off_location: e.target.value,
                                date: format(addDays(startRentDate, idx), 'yyyy-MM-dd').toString(),
                              }
                            : item,
                        ),
                  )
                }
                disable={isDetail}
              />
              <SelectFieldTime
                label="Jam Mulai"
                htmlFor="jam-mulai"
                placeholder="00:00"
                value={dayDuration[idx]?.booking_start_time || ''}
                onChange={(hour, minute) => {
                  if (idx == 0) {
                    setOrderData({
                      ...orderData,
                      order_detail: {
                        ...orderData.order_detail,
                        start_booking_time: `${hour}:${minute ? minute : '00'}`,
                        end_booking_time: `${hour}:${minute ? minute : '00'}`,
                      },
                    });
                  }
                  setDayDuration((prev) =>
                    prev[idx] === undefined
                      ? [
                          ...prev,
                          {
                            ...prev[idx],
                            booking_start_time: `${hour}:${minute ? minute : '00'}`,
                          },
                        ]
                      : prev.map((item, day) =>
                          day === idx
                            ? {
                                ...item,
                                booking_start_time: `${hour}:${minute ? minute : '00'}`,
                              }
                            : item,
                        ),
                  );
                }}
                disable={isDetail ? isDetail : startRentDate === '' ? true : false}
                showAllHours={true}
              />

              <SelectFieldTime
                label="Jam Selesai"
                htmlFor="jam-selesai"
                placeholder="00:00"
                value={dayDuration[idx]?.booking_end_time || ''}
                onChange={(hour, minute) => {
                  if (idx == 0) {
                    setOrderData({
                      ...orderData,
                      order_detail: {
                        ...orderData.order_detail,
                        end_booking_time: `${hour}:${minute ? minute : '00'}`,
                      },
                    });
                  }
                  setDayDuration((prev) =>
                    prev[idx] === undefined
                      ? [
                          ...prev,
                          {
                            ...prev[idx],
                            booking_end_time: `${hour}:${minute ? minute : '00'}`,
                          },
                        ]
                      : prev.map((item, day) =>
                          day === idx
                            ? {
                                ...item,
                                booking_end_time: `${hour}:${minute ? minute : '00'}`,
                              }
                            : item,
                        ),
                  );
                }}
                disable={
                  isDetail
                    ? isDetail
                    : startRentDate === '' || orderData.order_detail.start_booking_time === ''
                    ? true
                    : false
                }
                startHour={orderData.order_detail.start_booking_time}
                showAllHours={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WithDriverForm;
