import { createSlice } from '@reduxjs/toolkit';
import {
  getAllVehicles,
  getVehicleById,
  getVehiclesByFilter,
  uploadImageVehicle,
  getAFewVehicles,
  getVehicleDetailByFilter,
} from './action';

const initialState = {
  data: {},
  selected: {},
  fewVehicles: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const vehicle = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    // reducers goes here

    resetSelectedVehicle: (state) => {
      state.selected = {};
    },

    deleteImageVehicle: (state, action) => {
      state.selected.photo = action.payload;
    },

    resetDataVehicle: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicles.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllVehicles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllVehicles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getVehicleById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getVehicleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVehicleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(getVehicleDetailByFilter.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getVehicleDetailByFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVehicleDetailByFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(uploadImageVehicle.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(uploadImageVehicle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadImageVehicle.fulfilled, (state, action) => {
        state.isLoading = false;

        if (Object.keys(state.selected).length === 0) {
          state.selected = { photo: [action.payload] };
        } else {
          const oldPhotos = [...state.selected.photo];
          state.selected.photo = [...oldPhotos, action.payload];
        }
      })
      .addCase(getVehiclesByFilter.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getVehiclesByFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVehiclesByFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAFewVehicles.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.fewVehicles = {};
      })
      .addCase(getAFewVehicles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAFewVehicles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fewVehicles = action.payload;
      });
  },
});

export const { resetSelectedVehicle, deleteImageVehicle, resetDataVehicle } = vehicle.actions;
export default vehicle.reducer;
