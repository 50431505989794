import PreviewImage from 'components/Modals/PreviewImage';
import React from 'react';
import ReactDOM from 'react-dom';

const Thumbnail = ({ image, id, onClick, show, setShow }) => {
  return (
    <>
      <div className="thumbnail" onClick={onClick}>
        <img src={image} alt="thumbnail" data-id={id} />
      </div>
      {show &&
        ReactDOM.createPortal(<PreviewImage image={image} setShow={setShow} />, document.getElementById('modal'))}
    </>
  );
};

export default Thumbnail;
