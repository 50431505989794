import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllBanks = createAsyncThunk('banks/getAllBanks', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/banks`).then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getDetailAccountBank = createAsyncThunk('banks/getDetailAccountBank', async (userId, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/account-bank/user/${userId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getAllCurrencies = createAsyncThunk('currency/getAllCurrencies', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/currencies`).then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});
