import { useAppContext } from 'components/Context/AppContext';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as BusinessPartnerIcon } from 'icons/business-partner-filled-icon.svg';
import EmptyState from 'components/Global/EmptyState';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { deletePartnerAdmin, getAllVendorAdmin } from 'features/business-partner/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetSelectedAdmin } from 'features/business-partner/vendorAdminSlice';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Name', value: 'name' },
  { header: 'Email', value: 'email' },
  { header: 'Nomor Handphone', value: 'phone' },
];

const BusinessPartnerAdminList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowConfirmation, showToast } = useAppContext();

  const { data, status } = useSelector((state) => state.businessPartnerAdmin);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllVendorAdmin(currentPage));
  }, [currentPage]);

  const handleEdit = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetSelectedAdmin());
    navigate(`/business-partner-list/detail/${id}`);
  };

  const handleDelete = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda ingin menghapus data ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deletePartnerAdmin(id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil menghapus data!' });
          dispatch(getAllVendorAdmin(1));
          setCurrentPage(1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal menghapus data!' });
        }
      },
    });
  };

  // const processedData = data?.data?.map((item) => ({
  //   ...item,
  // }));

  return (
    <div>
      <TableWrapper
        icon={<BusinessPartnerIcon fill="#009EF7" width="25px" height="25px" />}
        title="Admin Partner Bisnis"
        CustomFilterComponent={true}
      >
        {status === 'loading' ? (
          <LoadingSpinner />
        ) : data?.data?.length === 0 && status !== 'loading' ? (
          <EmptyState />
        ) : (
          <>
            <Table
              column={column}
              actionBtn
              onDel={handleDelete}
              onEdit={handleEdit}
              data={data?.data}
              currentPage={currentPage}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={data?.paging?.total_data}
            />
          </>
        )}
      </TableWrapper>
    </div>
  );
};

export default BusinessPartnerAdminList;
