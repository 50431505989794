import { TableWrapper } from 'components/Global';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { ReactComponent as VehicleLogoIcon } from 'icons/custom-order-filled-icon.svg';
import WithoutDriverForm from './without-driver-form';
import WithDriverForm from './with-driver-form';
import AirportTransferForm from './airport-transfer-form';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { useSelector } from 'react-redux';

const orderTypes = [
  {
    id: 1,
    name: 'Dengan Supir',
    value: 'with_driver',
  },
  {
    id: 2,
    name: 'Tanpa Supir',
    value: 'without_driver',
  },
];

const DetailSewa = ({
  orderData,
  setOrderData,
  startRentDate,
  setStartRentDate,
  endRentDate,
  setEndRentDate,
  selectedPaymentType,
  setSelectedPaymentType,
  durationRent,
  setDurationRent,
  dayDuration,
  setDayDuration,
  selectedType,
  setSelectedType,
  isDetail,
  rentalLocationId,
  setRentalLocationId,
  orderType,
}) => {
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  return (
    <TableWrapper
      icon={<VehicleLogoIcon fill="#009EF7" width="25px" height="25px" />}
      title="Detail Sewa"
      style={{ marginBottom: 16 }}
    >
      <div className="business-partner-rental-custom-order__wrapper">
        <h2 className="business-partner-rental-custom-order__title" style={{ color: '#009ef7' }}>
          Detail Sewa
        </h2>
        <SelectFieldShuttle
          label="Lokasi Rental"
          htmlFor="lokasi-rental"
          className="with-driver-form__form__rental-location"
          data={rentalLocationData}
          value={rentalLocationId.id}
          onChange={(e) => setRentalLocationId(e)}
          placeholder="Lokasi Rental"
          searchFeature
          disable={isDetail}
        />
        {orderType === 'Daily' ? (
          <SelectFieldDropdown
            data={orderTypes}
            label="Tipe Sewa"
            htmlFor="tipe-sewa"
            placeholder="Pilih Tipe Sewa"
            value={selectedType}
            onChange={(e) => {
              setSelectedType(+e.target.id);
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  rental_delivery_location: '',
                  rental_delivery_location_detail: '',
                  rental_return_location: '',
                  rental_return_location_detail: '',
                  end_booking_date: '',
                  end_booking_time: '',
                  start_booking_date: '',
                  start_booking_time: '',
                  landing_time: '',
                  flight_number: '',
                },
              });
              setStartRentDate('');
              setEndRentDate('');
              setDurationRent(0);
            }}
            disable={isDetail}
          />
        ) : orderType === 'Airport Transfer' ? (
          <AirportTransferForm
            orderData={orderData}
            setOrderData={setOrderData}
            startRentDate={startRentDate}
            setStartRentDate={setStartRentDate}
            endRentDate={endRentDate}
            setEndRentDate={setEndRentDate}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            isDetail={isDetail}
          />
        ) : null}

        {selectedType === 1 ? (
          <WithDriverForm
            orderData={orderData}
            setOrderData={setOrderData}
            startRentDate={startRentDate}
            setStartRentDate={setStartRentDate}
            durationRent={durationRent}
            setDurationRent={setDurationRent}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            dayDuration={dayDuration}
            setDayDuration={setDayDuration}
            isDetail={isDetail}
          />
        ) : selectedType === 2 ? (
          <WithoutDriverForm
            orderData={orderData}
            setOrderData={setOrderData}
            startRentDate={startRentDate}
            setStartRentDate={setStartRentDate}
            endRentDate={endRentDate}
            setEndRentDate={setEndRentDate}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            isDetail={isDetail}
          />
        ) : null}
      </div>
    </TableWrapper>
  );
};

export default DetailSewa;
