import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronDown } from '../../../icons/chevron-down.svg';

const SelectFieldSearch = ({
  label,
  name,
  htmlFor,
  value, // state
  data = [],
  className,
  placeholder,
  disable,
  onClick,
}) => {
  const dropdownRef = useRef(null);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  // DROPDOWN SHOW FUNCTION
  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  // CLICK FUNCTION
  const clickhandler = (e) => {
    onClick(e.target.id);
    setInputValue('');
    setIsShowDropdown(false);
  };

  // CLICK OUTSIDE
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputValue === '') {
      setFilteredData(data);
    } else {
      const dataFilter = data?.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()));
      setFilteredData(dataFilter);
    }
  }, [inputValue, data]);

  return (
    <div className={clsx('select-field-search', className)} ref={dropdownRef}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="select-field-search__label"
          style={{ pointerEvents: disable ? 'none' : 'auto' }}
        >
          {label}
        </label>
      )}

      <div className={clsx('select-field-search__field', disable && 'disable')} onClick={toggleDropdown}>
        <input
          id={htmlFor}
          value={isShowDropdown ? inputValue : data?.find((item) => item.id == value)?.name}
          name={name}
          className="select-field-search__field__input"
          placeholder={placeholder}
          onChange={(e) => setInputValue(e.target.value)}
        />

        {!disable && <ChevronDown className={clsx('select-field-search__field__chevron', isShowDropdown && 'down')} />}

        {isShowDropdown && (
          <ul className="select-field-search__field__dropdown">
            {!filteredData || filteredData.length === 0 ? (
              <li>Data Not Found</li>
            ) : (
              filteredData.map((item) => (
                <li key={item.id} id={item.id} onClick={clickhandler}>
                  {item.name}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default React.memo(SelectFieldSearch);
