import { createSlice } from '@reduxjs/toolkit';
import { getAllVendorPartner, getVendorPartnerDetail } from './actions';

const initialState = {
  data: {},
  selected: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: {},
};

export const businessPartnerVendor = createSlice({
  name: 'businessPartnerVendor',
  initialState,
  reducers: {
    resetSelectedBusiness: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVendorPartner.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllVendorPartner.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllVendorPartner.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getVendorPartnerDetail.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getVendorPartnerDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVendorPartnerDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selected = action.payload;
      });
  },
});

export const { resetSelectedBusiness } = businessPartnerVendor.actions;
export default businessPartnerVendor.reducer;
