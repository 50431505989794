import { PaginationTable, Table, TableWrapper } from 'components/Global';
import React, { useEffect, useState } from 'react';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import EmptyState from 'components/Global/EmptyState';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { deleteDriverById, getAllDrivers } from 'features/drivers/actions';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { resetSelectedDriver } from 'features/drivers/slice';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama', value: 'name' },
  { header: 'Email', value: 'email' },
  { header: 'No. Handphone', value: 'phone_number' },
  { header: 'Status', value: 'status' },
];

const DriverList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowConfirmation, showToast } = useAppContext();

  const { data, isLoading } = useSelector((state) => state.drivers);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllDrivers(currentPage));
  }, [currentPage]);

  const handleDetail = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetSelectedDriver());
    navigate(`/driver/${id}`);
  };

  const handleEdit = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetSelectedDriver());
    navigate(`/driver/${id}?is_edit=true`);
  };

  const handleDelete = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda ingin menghapus data ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteDriverById(id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil menghapus data!' });
          dispatch(getAllDrivers(1));
          setCurrentPage(1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal menghapus data!' });
        }
      },
    });
  };

  const processedData = data?.users?.map((item) => ({
    id: item.id,
    name: item.name,
    email: item.email,
    phone_number: item.phone,
    status: item.is_deactivated ? 'inactive' : 'active',
  }));

  return (
    <div>
      <TableWrapper
        icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />}
        title="Driver"
        CustomFilterComponent={true}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : processedData?.length === 0 && !isLoading ? (
          <EmptyState />
        ) : (
          <>
            <Table
              column={column}
              actionBtn
              onDetail={handleDetail}
              onDel={handleDelete}
              onEdit={handleEdit}
              data={processedData}
              currentPage={currentPage}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={data?.pagination?.total_data}
            />
          </>
        )}
      </TableWrapper>
    </div>
  );
};

export default DriverList;
