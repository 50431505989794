import { createSlice } from '@reduxjs/toolkit';
import { createPublishTask } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const publishTask = createSlice({
  name: 'publishTask',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPublishTask.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = {};
      })
      .addCase(createPublishTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createPublishTask.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default publishTask.reducer;
