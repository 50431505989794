import React from 'react';
import { Button, InputField } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import { useEffect } from 'react';
import { useAppContext } from 'components/Context/AppContext';
import { useSelector } from 'react-redux';
import { convertBase64toImage } from 'utils/functionality';

// UPLOAD IMAGE ARRAY
const uploadImageField = [
  {
    id: 'uif1',
    title: 'KTP',
    cheked: false,
  },
  {
    id: 'uif2',
    title: 'SIM',
    checked: false,
  },
];

// REGEX VALIDATION
const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const DetailPenyewa = ({
  nextPage,
  orderData,
  setOrderData,
  isOrderConfirmation,
  ktpImageLink,
  setKtpImageLink,
  simImageLink,
  setSimImageLink,
  orderDataLocalStorage,
  setOrderDataLocalStorage,
}) => {
  // CONTEXT
  const { showToast, setShowSpinner } = useAppContext();

  // GLOBAL STATE
  const { status: statusUploadImage } = useSelector((state) => state.createOrder);

  // SCROLL TO TOP WHEN COMPONENT IS LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // CHANGE FUNCTION
  const changeHandler = (e) => {
    const propsName = e.target.name;

    const newOrderData = { ...orderData, [propsName]: e.target.value };
    setOrderData(newOrderData);
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
  };

  // UPLOAD IMAGE FUNCTION
  const uploadImageAction = async (file, name) => {
    const lowerCaseName = name.toLocaleLowerCase();
    let identityObject;

    if (lowerCaseName === 'ktp') {
      const convertedImage = await convertBase64toImage(file.file, 'identity-image');
      setKtpImageLink(convertedImage);

      if (orderData.order_detail.identity === null) {
        identityObject = { [lowerCaseName]: file.file, sim: null };
      } else {
        identityObject = { ...orderData.order_detail.identity, [lowerCaseName]: file.file };
      }

      const newOrderData = {
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          identity: identityObject,
        },
      };

      setOrderData(newOrderData);
      setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, ktpImageLink: file.file });
    } else {
      const convertedImage = await convertBase64toImage(file.file, 'identity-image');
      setSimImageLink(convertedImage);

      if (orderData.order_detail.identity === null) {
        identityObject = { [lowerCaseName]: file.file, ktp: null };
      } else {
        identityObject = { ...orderData.order_detail.identity, [lowerCaseName]: file.file };
      }

      const newOrderData = {
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          identity: identityObject,
        },
      };
      setOrderData(newOrderData);
      setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, simImageLink: file.file });
    }
  };

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // SHOW SPINNER WHEN UPLOAD IMAGE
  useEffect(() => {
    if (statusUploadImage === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [statusUploadImage]);

  // FUCNTION VALIDATION & TO THE NEXT PAGE
  const submitHandler = (e) => {
    e.preventDefault();

    // some validation
    const validUsername = orderData?.user_name.replace(/\s/g, '').length !== 0;
    const validUserEmail = new RegExp(regexEmail).test(orderData?.email);
    const validUserWaNumber = orderData?.wa_number.replace(/s/g, '').length !== 0;
    // &&
    // orderData?.wa_number.replace(/s/g, '').length >= 10 &&
    // orderData?.wa_number.replace(/s/g, '').length < 14;
    // const validIdentity = orderData?.order_detail?.identity;
    const validKTP = orderData?.order_detail?.identity?.ktp;
    // const validSIM = orderData?.order_detail?.identity?.sim;

    // show toast if theres an error
    if (!validUsername) {
      showToast({ type: 'error', message: 'Nama tidak valid' });
      return;
    } else if (!validUserEmail) {
      showToast({ type: 'error', message: 'Email tidak valid' });
      return;
    } else if (!validUserWaNumber) {
      showToast({ type: 'error', message: 'Nomor WA tidak valid' });
      return;
    } else if (!validKTP) {
      showToast({ type: 'error', message: 'Upload KTP' });
      return;
    }

    nextPage();
    setOrderDataLocalStorage({ ...orderDataLocalStorage, page: 2 });
  };

  return (
    <form className="detail-penyewa">
      {/* DETAIL PENYEWA FORM */}
      <div className="detail-penyewa__detail-penyewa-form">
        <h2 className="detail-penyewa__detail-penyewa-form__title">Detail Penyewa</h2>
        <div className="detail-penyewa__detail-penyewa-form__form">
          {/* input */}
          <div className="detail-penyewa__detail-penyewa-form__form__one">
            <InputField
              type="text"
              label="Nama Penyewa"
              htmlFor="nama-penyewa"
              placeholder="Tulis Nama Penyewa"
              name="user_name"
              value={orderData.user_name}
              onChange={changeHandler}
              disable={isOrderConfirmation}
            />
            <InputField
              type="text"
              label="Email"
              htmlFor="email-penyewa"
              placeholder="Masukan Email"
              name="email"
              value={orderData.email}
              onChange={changeHandler}
              disable={isOrderConfirmation}
            />
            <InputTelpField
              label="No Telpon / Whatsapp"
              htmlFor="telp-penyewa"
              value={orderData.wa_number || ''}
              name="wa_number"
              onChange={changeHandler}
              disable={isOrderConfirmation}
              countryCodeValue={orderData.phone_country_code}
              onSelectCountry={(code) => {
                const newOrderData = { ...orderData, phone_country_code: code };
                setOrderData(newOrderData);
                setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
              }}
              onWheel={numberInputOnWheelPreventChange}
            />
          </div>
          {/* upload image */}
          <div className="detail-penyewa__detail-penyewa-form__form__two">
            {uploadImageField.map((item) => (
              <div key={item.id}>
                <div>
                  <h3>
                    {item.title} {item.title === 'SIM' && `(Opsional)`}
                  </h3>
                </div>
                {/* upload image field */}
                <UploadImageCustomOrder
                  htmlFor={item.title}
                  uploadAction={uploadImageAction}
                  imageFile={item.title === 'KTP' ? ktpImageLink : simImageLink}
                  base64={true}
                  disable={isOrderConfirmation}
                  customOrder
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="detail-penyewa__button-action">
          <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
            Lanjutkan
          </Button>
        </div>
      )}
    </form>
  );
};

export default DetailPenyewa;
