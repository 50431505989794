import Button from 'components/Global/Button';
import InputField from 'components/Global/InputField';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as DeleteRoundedIcon } from 'icons/delete-rounded-icon.svg';
import ReactDOM from 'react-dom';
import {
  addListZones,
  createNewZone,
  createZoneImage,
  deleteListZoneById,
  editListZoneById,
  editZoneById,
  getZoneDetailById,
} from 'features/zone/actions';
import { checkEmptyObject, checkPermission } from 'utils/functionality';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import PreviewImage from 'components/Modals/PreviewImage';

const baseUrl = process.env.REACT_APP_IMAGES;

const ZoneInputCard = ({ data, removeHandler, locationId, uploadAction, showImage, setShowImage, currency }) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [listZones, setListZones] = useState([{ name: '' }]);
  const [vehiclePrices, setVehiclePrices] = useState([
    {
      category_id: 0,
      price: '',
    },
  ]);
  const [zoneName, setZoneName] = useState('');
  const [selectedImage, setSelectedImage] = useState({
    name: '',
    url: '',
  });
  const [searchParams] = useSearchParams();
  const vehicleCategory = useSelector((state) => state.category.data);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const [mappedCategory, setMappedCategory] = useState([]);
  const { showToast, setShowConfirmation } = useAppContext();
  const dispatch = useDispatch();
  const active = searchParams.get('set-active');

  const disableOnSavedZone = active === 'detail';
  const notShowOnDetail = active === 'create' || active === 'edit';

  const onChangeVehicleCategory = (e, index) => {
    const { id } = e.target;
    setVehiclePrices((prev) => prev.map((item, idx) => (idx == index ? { ...item, category_id: +id } : item)));
    setMappedCategory((prev) => prev.map((item) => (item.id == +id ? { ...item, chosen: true } : item)));
  };

  const onChangeVehicleCategoryPrice = (e, index) => {
    const { value } = e.target;
    setVehiclePrices((prev) => prev.map((item, idx) => (idx == index ? { ...item, price: +value } : item)));
  };

  const onChangeListZone = (e, index) => {
    const { value } = e.target;
    setListZones((prev) => prev.map((item, idx) => (idx == index ? { ...item, name: value } : item)));
  };

  const addListZoneHandler = () => {
    const newListZones = [...listZones, { name: '' }];
    setListZones(newListZones);
  };

  const addVehiclePriceHandler = () => {
    const newVehiclePrice = [...vehiclePrices, { category_id: 0, price: '' }];
    setVehiclePrices(newVehiclePrice);
  };

  const deleteVehiclePriceHandler = (index) => {
    const categoryToAdd = vehiclePrices[index];
    if (categoryToAdd.category_id) {
      const getCategory = mappedCategory.find((item) => item.id == categoryToAdd.category_id);
      setMappedCategory((prev) => [...prev, { ...getCategory, chosen: false }]);
    }
    setVehiclePrices((prev) => prev.filter((_, idx) => idx !== index));
  };

  const deleteListZoneHandler = (index, id) => {
    if (id) {
      setShowConfirmation({
        message: 'Apakah Anda ingin menghapus Data List Zona ini?',
        show: true,
        onClick: async () => {
          try {
            await dispatch(deleteListZoneById(id)).unwrap();
            showToast({
              type: 'success',
              message: 'Berhasil Menghapus Data List Zona',
            });
            dispatch(getZoneDetailById(data.id));
            setTimeout(() => {
              scrollToElement();
            }, 200);
          } catch (error) {
            showToast({
              type: 'warning',
              message: 'Gagal Menghapus Data List Zona!',
            });
          }
        },
      });
      return;
    }
    setListZones((prev) => prev.filter((_, idx) => idx !== index));
  };

  const saveHandler = async () => {
    const mappedVehiclePrices = vehiclePrices.map(
      (item) =>
        item.category_id && {
          category_id: item.category_id,
          price: item.price ? item.price : 0,
        },
    );

    const isListZoneEmpty = listZones.find((item) => item.name === '') || listZones.length === 0;
    const isVehiclePriceEmpty = mappedVehiclePrices.includes(0) || mappedVehiclePrices.length === 0;

    if (!locationId) {
      showToast({
        type: 'warning',
        message: 'Harap Memilih Daerah Sewa',
      });
      return;
    }

    if (!zoneName) {
      showToast({
        type: 'warning',
        message: 'Harap Mengisi Nama Zona',
      });
      return;
    }

    if (!selectedImage.url) {
      showToast({
        type: 'warning',
        message: 'Harap Memilih Gambar Zona',
      });
      return;
    }

    if (isVehiclePriceEmpty) {
      showToast({
        type: 'warning',
        message: 'Harap Mengisi Data Kategori Mobil',
      });
      return;
    }

    if (isListZoneEmpty) {
      showToast({
        type: 'warning',
        message: 'Harap Mengisi Detail Daerah Zona',
      });
      return;
    }

    const listZonesToAdd = [...listZones].filter((item) => !item.id);
    let listZonesToUpdate = [];

    listZones
      .filter((item) => item.id)
      .map((item) => {
        const isExist = data.list.find((i) => i.id === item.id);
        if (isExist?.id == item.id) {
          if (isExist?.name.toLowerCase() !== item.name.toLowerCase()) {
            listZonesToUpdate.push(item);
          }
        }
      });

    const payload = {
      name: zoneName,
      location_id: +locationId,
      category: mappedVehiclePrices,
    };

    // To get Zone Id after create new zone
    let zone_response;
    // To get Image Zone Id after create image zone
    let image_zone_id;

    if (selectedImage?.url) {
      try {
        image_zone_id = await dispatch(createZoneImage({ url: selectedImage.url, location_id: +locationId })).unwrap();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        showToast({
          type: 'error',
          message: 'Gagal Menambahkan Gambar Zona!',
        });
      }
    }

    // CREATE OR EDIT ZONE
    try {
      if (active === 'edit') {
        await dispatch(
          editZoneById({
            payload: { ...payload, zone_image_id: selectedImage?.url ? image_zone_id.id : undefined },
            id: data.id,
          }),
        ).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil Merubah Data Zona!',
        });
      } else {
        zone_response = await dispatch(
          createNewZone({ ...payload, image_zone_id: selectedImage?.url ? image_zone_id.id : undefined }),
        ).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil Membuat Zona Baru!',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (active === 'create') {
        showToast({
          type: 'error',
          message: 'Gagal Membuat Zona Baru!',
        });
        return;
      }
      showToast({
        type: 'error',
        message: 'Gagal Merubah Data Zona!',
      });
    } finally {
      if (active === 'edit') {
        dispatch(getZoneDetailById(data.id));
      }
    }

    // ADD OR EDIT LIST ZONE
    if (active === 'create') {
      try {
        if (listZonesToUpdate.length > 0) {
          listZonesToUpdate.map(async (item) => {
            await dispatch(
              editListZoneById({ payload: { name: item.name, zone_id: item.zone_id }, id: item.id }),
            ).unwrap();
          });
        }
        if (listZonesToAdd.length > 0) {
          const mappedListZone = listZonesToAdd.map((list_zone) => ({
            ...list_zone,
            zone_id: zone_response.id,
          }));
          await dispatch(addListZones({ group_list_zone: mappedListZone })).unwrap();
        }
        showToast({
          type: 'success',
          message: 'Berhasil Menambahkan Daerah List Zona!',
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        showToast({
          type: 'error',
          message: 'Gagal Menambah Data List Zona!',
        });
      } finally {
        navigate('/zone');
      }
    } else {
      try {
        if (listZonesToUpdate.length > 0) {
          listZonesToUpdate.map(async (item) => {
            await dispatch(
              editListZoneById({ payload: { name: item.name, zone_id: item.zone_id }, id: item.id }),
            ).unwrap();
          });
        }
        if (listZonesToAdd.length > 0) {
          const mappedListZone = listZonesToAdd.map((list_zone) => ({
            ...list_zone,
            zone_id: data.id,
          }));
          await dispatch(addListZones({ group_list_zone: mappedListZone })).unwrap();
        }
        showToast({
          type: 'success',
          message: 'Berhasil Merubah Daerah List Zona!',
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        showToast({
          type: 'error',
          message: 'Gagal Menambah Data List Zona!',
        });
      } finally {
        dispatch(getZoneDetailById(data.id));
        setTimeout(() => {
          scrollToElement();
        }, 200);
      }
    }
  };

  useEffect(() => {
    if (checkEmptyObject(vehicleCategory)) return;
    if (data) {
      setMappedCategory(
        vehicleCategory.category.map((cat) =>
          data.category?.find((cat2) => cat2.category_id == cat.id)
            ? { id: cat.id, category_id: cat.id, name: cat.name, chosen: true }
            : { id: cat.id, category_id: cat.id, name: cat.name, chosen: false },
        ),
      );
    } else {
      setMappedCategory(
        vehicleCategory.category.map((item) => ({ id: item.id, category_id: item.id, name: item.name, chosen: false })),
      );
    }
  }, [vehicleCategory, data]);

  useEffect(() => {
    if (!data || active == 'create') return;
    setZoneName(data.name);
    setVehiclePrices(
      data.category?.length > 0
        ? data.category.map((item) => ({ ...item, id: item.category_id }))
        : [
            {
              category_id: 0,
              price: '',
            },
          ],
    );
    setListZones(
      data.list?.length > 0
        ? data.list
            .map((item) => ({ id: item.id, name: item.name, zone_id: data.id }))
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        : [{ name: '' }],
    );
    setSelectedImage({ name: data.zone_image?.url, url: data.zone_image?.url });
  }, [data, active]);

  const scrollToElement = () => {
    const getCardId = document.getElementById(`zone-card-${data.id}`);
    getCardId?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleUploadImage = async (e) => {
    if (!e.target.files.length) return;

    const imageUpload = await uploadAction(e.target.files[0]);
    setSelectedImage(imageUpload);
  };

  return (
    <>
      <div className="zone-input-card" id={`zone-card-${data.id}`}>
        {active === 'edit' && checkPermission(offCanvasMenu, currentMenu, 'delete') && (
          <div className="zone-input-card__remove-btn">
            <DeleteRoundedIcon onClick={removeHandler} />
          </div>
        )}
        <div className="zone-input-card__top">
          <InputField
            label="Nama Zona"
            placeholder="Tulis Nama Zona"
            disable={disableOnSavedZone}
            value={zoneName}
            onChange={(e) => setZoneName(e.target.value)}
          />
          <div className="input-group__image-input">
            <h4>Gambar Zona</h4>
            <div className="image-upload">
              <input type="file" accept="image/*" hidden ref={inputRef} onChange={handleUploadImage} />
              {selectedImage?.name ? (
                <p
                  className="link-upload"
                  onClick={() => {
                    if (disableOnSavedZone) {
                      setShowImage(true);
                      return;
                    }
                    inputRef.current.click();
                  }}
                >
                  {selectedImage?.name}
                </p>
              ) : (
                <button className="btn-upload" onClick={() => inputRef.current.click()} disabled={!notShowOnDetail}>
                  Browse..
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="zone-input-card__vehicle-price">
          {Array.from({ length: vehiclePrices.length }, (_, i) => i + 1).map((item, idx) => (
            <div className="zone-input-card__top" key={item}>
              <SelectFieldDropdown
                label="Kategori Mobil"
                value={vehiclePrices.length > 0 ? vehiclePrices[idx]?.category_id : 0}
                data={[...mappedCategory].filter((item) =>
                  vehiclePrices[idx].category_id ? item.chosen : !item.chosen,
                )}
                disable={disableOnSavedZone || vehiclePrices[idx]?.category_id}
                onChange={(e) => onChangeVehicleCategory(e, idx)}
                placeholder="Pilih Kategori"
                name="category"
              />
              <InputField
                label="Harga Mobil"
                type="number"
                value={vehiclePrices.length > 0 ? vehiclePrices[idx]?.price || '' : ''}
                placeholder="0"
                disable={disableOnSavedZone}
                onChange={(e) => onChangeVehicleCategoryPrice(e, idx)}
                name="price"
                price
                currency={currency}
              />
              <div className="vehicle-price__buttons">
                {notShowOnDetail ? <DeleteRoundedIcon onClick={() => deleteVehiclePriceHandler(idx)} /> : null}
              </div>
            </div>
          ))}
        </div>

        <div className="zone-input-card__divider">
          {notShowOnDetail ? (
            <Button
              onClick={addVehiclePriceHandler}
              className="zone-input-card__add-list-vehicle-price"
              width={26}
              height={26}
            >
              <PlusIcon />
            </Button>
          ) : null}
        </div>

        <div className="zone-input-card__list-zone">
          <label className="input-text-title">Detail</label>
          {Array.from({ length: listZones?.length }, (_, i) => i + 1).map((item, idx) => (
            <div className="list-zone" key={item}>
              <InputField
                disable={disableOnSavedZone}
                value={listZones.length > 0 ? listZones[idx].name : ''}
                placeholder="Tulis Detail"
                onChange={(e) => onChangeListZone(e, idx)}
              />
              <div className="list-zone__buttons">
                {notShowOnDetail ? (
                  <DeleteRoundedIcon onClick={() => deleteListZoneHandler(idx, listZones[idx]?.id)} />
                ) : null}
              </div>
            </div>
          ))}
        </div>

        <div className="zone-input-card__divider">
          {notShowOnDetail ? (
            <Button onClick={addListZoneHandler} className="zone-input-card__add-list-zone" width={26} height={26}>
              <PlusIcon />
            </Button>
          ) : null}
        </div>
        {notShowOnDetail && (
          <div className="zone-input-card__save-btn">
            <Button className="button" width={208} size="sm" onClick={saveHandler}>
              Simpan
            </Button>
          </div>
        )}
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={`${baseUrl}${selectedImage?.url}`} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default ZoneInputCard;
