import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { InputField, TableWrapper } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import VehiclePhotos from '../VechiclePhotos';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { setCurrency } from 'utils/functionality';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const WithoutDriverTask = ({ data, customer, setShowImage, selectedDriverTaskData, airportTransfer }) => {
  const mappingPhotoData = (data, progres) => {
    if (!data) {
      return {};
    }

    const theItem = data.find((item) => item.progres === progres);

    return theItem;
  };

  const getVehiclePlate = (orderType) => {
    if (!orderType) return;

    if (orderType == '1' || orderType == '2') {
      return data?.order_detail?.vehicle?.license_number;
    } else if (orderType == '3' || orderType == '4') {
      return data?.partner_vehicle?.license_plate;
    }
  };

  const getVehicleName = (orderType) => {
    if (!orderType) return;

    if (orderType == '1' || orderType == '2') {
      return `${data?.order_detail?.vehicle?.brand_name} ${data?.order_detail?.vehicle?.name}`;
    } else if (orderType == '3' || orderType == '4') {
      return `${data?.partner_vehicle?.name}`;
    }
  };

  return (
    <>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Order Detail">
        <h2>Detail Penyewa</h2>
        <div className="detail-driver-task__order-detail">
          <InputField label="Nama Lengkap" disable readOnly value={data?.user_name || '-'} />

          <InputField label="No Order" disable readOnly value={data?.order_key || '-'} />

          <InputTelpField
            label="No Handphone"
            countryCodeValue={data?.phone_country_code || '+62'}
            disable
            readOnly
            value={data?.wa_number || '-'}
          />

          <InputField label="Plat Nomer" disable readOnly value={getVehiclePlate(data?.order_type_id) || '-'} />

          <InputField label="Jumlah Penumpang" disable readOnly value={data?.order_detail?.passenger_number || '0'} />

          <InputField label="Mobil" disable readOnly value={getVehicleName(data?.order_type_id)} />
        </div>

        <h2>Detail Sewa</h2>
        <div className="detail-driver-task__order-detail">
          <InputField
            label={airportTransfer ? 'Lokasi Penjemputan' : 'Lokasi Pengantaran'}
            disable
            readOnly
            value={data?.order_detail?.rental_delivery_location || '-'}
          />

          <InputField
            label={airportTransfer ? 'Detail Lokasi Penjemputan' : 'Detail Lokasi Pengantaran'}
            disable
            readOnly
            value={data?.order_detail?.rental_delivery_location_detail || '-'}
          />

          <InputField
            label={airportTransfer ? 'Lokasi Pengantaran' : 'Lokasi Pengembalian'}
            disable
            readOnly
            value={data?.order_detail?.rental_return_location || '-'}
          />

          <InputField
            label={airportTransfer ? 'Detail Lokasi Pengantaran' : 'Detail Lokasi Pengembalian'}
            disable
            readOnly
            value={data?.order_detail?.rental_return_location_detail || '-'}
          />

          <div className="rent-period">
            <InputField
              label="Tanggal Sewa"
              disable
              readOnly
              value={
                data?.order_detail?.start_booking_date
                  ? format(new Date(data?.order_detail?.start_booking_date), 'dd MMMM yyyy', { locale: id })
                  : '-'
              }
            />
            <InputField
              label="Jam Mulai"
              disable
              readOnly
              value={
                data?.order_detail?.start_booking_date && data?.order_detail?.start_booking_time
                  ? format(
                      new Date(`${data?.order_detail?.start_booking_date} ${data?.order_detail?.start_booking_time}`),
                      'hh:mm aa',
                      { locale: id },
                    )
                  : '-'
              }
            />
          </div>

          <div className="rent-period">
            <InputField
              label="Tanggal Sewa"
              disable
              readOnly
              value={
                data?.order_detail?.end_booking_date
                  ? format(new Date(data?.order_detail?.end_booking_date), 'dd MMMM yyyy', { locale: id })
                  : '-'
              }
            />
            <InputField
              label="Jam Selesai"
              disable
              readOnly
              value={
                data?.order_detail?.end_booking_date && data?.order_detail?.end_booking_time
                  ? format(
                      new Date(`${data?.order_detail?.end_booking_date} ${data?.order_detail?.end_booking_time}`),
                      'hh:mm aa',
                      { locale: id },
                    )
                  : '-'
              }
            />
          </div>

          <div className="identity-image">
            <h3>Foto SIM</h3>
            <div className="image">
              <div
                className="image-wrapper"
                onClick={() => {
                  if (data?.is_admin_creation) {
                    return setShowImage({
                      show: true,
                      image: data?.order_detail.identity?.sim,
                    });
                  } else {
                    return setShowImage({
                      show: true,
                      image: `${IMAGE_BASE_URL}${customer?.PersonalInfos?.sim}`,
                    });
                  }
                }}
                style={{
                  cursor: data?.order_detail?.identity?.sim || customer?.PersonalInfos?.sim ? 'pointer' : 'default',
                }}
              >
                {data?.is_admin_creation && data?.order_detail?.identity?.sim ? (
                  <img src={data?.order_detail?.identity?.sim} alt="sim image" />
                ) : !data?.is_admin_creation && customer?.PersonalInfos?.sim ? (
                  <img src={`${IMAGE_BASE_URL}${customer?.PersonalInfos?.sim}`} alt="sim image" />
                ) : (
                  <div className="no-available">
                    <img src="/assets/image/no-image-available.png" className="img" />
                    <p>No Image Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="identity-image">
            <h3>Foto KTP</h3>
            <div className="image">
              <div
                className="image-wrapper"
                onClick={() => {
                  if (data?.is_admin_creation) {
                    return setShowImage({
                      show: true,
                      image: data?.order_detail.identity?.ktp,
                    });
                  } else {
                    return setShowImage({
                      show: true,
                      image: `${IMAGE_BASE_URL}${customer?.PersonalInfos?.ktp}`,
                    });
                  }
                }}
                style={{
                  cursor: data?.order_detail?.identity?.ktp || customer?.PersonalInfos?.ktp ? 'pointer' : 'default',
                }}
              >
                {data?.is_admin_creation && data?.order_detail?.identity?.ktp ? (
                  <img src={data?.order_detail?.identity?.ktp} alt="sim image" />
                ) : !data?.is_admin_creation && customer?.PersonalInfos?.ktp ? (
                  <img src={`${IMAGE_BASE_URL}${customer?.PersonalInfos?.ktp}`} alt="sim image" />
                ) : (
                  <div className="no-available">
                    <img src="/assets/image/no-image-available.png" className="img" />
                    <p>No Image Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {selectedDriverTaskData?.status !== 'OPEN' && (
          <>
            <h2>Detail Driver</h2>
            <div className="detail-driver-task__driver-detail">
              <InputField
                label="Fee Per-Task"
                disable
                readOnly
                value={
                  selectedDriverTaskData?.fee_per_task
                    ? setCurrency(selectedDriverTaskData.fee_per_task).replace('Rp', data?.currency)
                    : '-'
                }
              />

              <InputField label="ID Driver" disable readOnly value={selectedDriverTaskData?.driver_id || '-'} />
            </div>
          </>
        )}
      </TableWrapper>

      <div className="detail-driver-task__vehicle-photos-task">
        <VehiclePhotos
          title="Ambil dari Garasi"
          photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'RUNNING')}
          setShowImage={setShowImage}
        />
        {/* <VehiclePhotos title="Antar Mobil" photoData={photoData ? photoData[1] : {}} setShowImage={setShowImage} />
        <VehiclePhotos title="Ambil Mobil" photoData={photoData ? photoData[2] : {}} setShowImage={setShowImage} /> */}
        <VehiclePhotos
          title="Parkir ke Garasi"
          photoData={mappingPhotoData(selectedDriverTaskData?.progress, 'FINISH')}
          setShowImage={setShowImage}
        />
      </div>
    </>
  );
};

export default WithoutDriverTask;
