import React from 'react';
import { InputField } from 'components/Global';
import { setCurrency } from 'utils/functionality';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';

const RincianBiaya = ({ data, isApproval }) => {
  return (
    <div className="detail-order-with-driver__price-detail">
      <div className="detail-order-with-driver__price-detail-title">
        <h1>Rincian Biaya</h1>
      </div>
      <div className="detail-order-with-driver__price-detail-body">
        <InputField label="Biaya Sewa Mobil" disabled value={setCurrency(data?.booking_price, data?.currency) || '-'} />
        <InputField label="Deposit" disabled value={setCurrency(data?.deposit, data?.currency) || '-'} />
        {data?.over_time_price > 0 && (
          <InputField label="Overtime" disabled value={setCurrency(data?.over_time_price, data?.currency) || '-'} />
        )}
        {/* <InputField label="One Day Charge" disabled value={setCurrency(data?.one_day_order_charge) || '-'} /> */}
        {data?.outside_operational_charge > 0 && (
          <InputField
            label="Outside Operational Hour Charge"
            disabled
            value={setCurrency(data?.outside_operational_charge, data?.currency) || '-'}
          />
        )}

        {data?.exceed_passenger_price > 0 && (
          <InputField
            label="Exceed Max Passenger Charge"
            disabled
            value={setCurrency(data?.exceed_passenger_price, data?.currency) || '-'}
          />
        )}
        <InputField
          label="Tipe Pembayaran"
          disabled
          value={
            data?.type === 'FULL'
              ? 'Pembayaran Full'
              : `Pembayaran DP ${
                  data?.order_detail?.dp_rule?.formula_percentage?.value
                    ? `${data.order_detail.dp_rule.formula_percentage?.value}%`
                    : ''
                }`
          }
        />
        <InputField
          label={`Biaya Bayar DP ${
            data?.order_detail?.dp_rule?.formula_percentage?.value
              ? `${data.order_detail.dp_rule.formula_percentage.value}%`
              : ''
          }`}
          disabled
          value={setCurrency(data?.down_payment, data?.currency) || '-'}
        />
        <InputField
          label="Biaya selanjutnya yang harus dibayar"
          disabled
          value={setCurrency(data?.remainder, data?.currency) || '-'}
        />
        <InputField
          label="Pembayaran Dengan Point"
          disabled
          value={data?.point}
          icon={<PointsIcon />}
          iconPosition="start"
        />
        {data?.type === 'HALF' ? (
          <InputField
            label="Status Pembayaran DP"
            disabled
            value={data?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
          />
        ) : null}
        <InputField
          label="Charge Payment Gateway Platform"
          disabled
          value={setCurrency(data?.disbursement?.total_amount_external_payment_fee, data?.currency) || '-'}
        />
        <InputField
          label="Customer Service Platform"
          disabled
          value={setCurrency(data?.disbursement?.total_amount_customer_service_fee, data?.currency) || '-'}
        />
        {!isApproval ? (
          <InputField
            label="Total Harga Bersih"
            disabled
            value={setCurrency(data?.disbursement?.total_net_amount, data?.currency) || '-'}
          />
        ) : null}
        {!isApproval ? (
          <InputField
            label="Total Bruto"
            disabled
            value={setCurrency(data?.disbursement?.total_amount_with_customer_service_fee, data?.currency) || '-'}
          />
        ) : null}
        <InputField
          label="Total Keseluruhan"
          disabled
          value={setCurrency(data?.total_payment, data?.currency) || '-'}
        />
        {Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
          <InputField
            label="Harga Selisih"
            disabled
            value={setCurrency(data?.total_diff_price_with_approval, data?.currency) || '-'}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RincianBiaya;
