import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import DetailPenyewa from './detail-penyewa';
import DetailSewa from './detail-sewa';
import DetailBisnisPartner from './detail-bisnis-partner';
import { Button, InputField } from 'components/Global';
import { deleteImageVehicle } from 'features/vehicle/vehicleSlice';
import { changeOrderStatus, createOrderBusinessPartner, fetchOrderById } from 'features/orders/actions';
import { checkEmptyObject } from 'utils/functionality';
import { parseISO } from 'date-fns';
import { getAllRentalLocation } from 'features/rental-location/actions';

const initialState = {
  order_type_id: 3,
  user_name: '',
  phone_number: '',
  phone_country_code: '+62',
  wa_number: '',
  email: '',
  booking_price: 0,
  type: 'FULL',
  service_fee: 0,
  rental_delivery_fee: 0,
  rental_return_fee: 0,
  insurance_fee: 0,
  total_payment: 0,
  deposit: 0,
  deposit_e_toll: 0,
  over_time_price: 0,
  over_time: 0,
  order_violations: [],
  order_detail: {
    is_take_from_rental_office: false,
    rental_delivery_location: '',
    rental_delivery_location_detail: '',
    rental_return_location: '',
    rental_return_location_detail: '',
    end_booking_date: '',
    end_booking_time: '',
    start_booking_date: '',
    start_booking_time: '',
    landing_time: '',
    flight_number: '',
    identity: null,
    booking_zones: [],
  },
  partner_vehicle: {
    name: '',
    license_plate: '',
    partner_vehicle_image: [],
  },
  remainder: 0,
  price_per_day: 0,
  exceed_passenger_price: 0,
  down_payment: 0,
};

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const BusinessPartnerCustomOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { transactionKey } = useParams();

  const { showToast, setShowSpinner, setShowConfirmation, setShowBroadcastTask } = useAppContext();

  const selectedVehicle = useSelector((state) => state.vehicle?.selected);
  const selectedOrder = useSelector((state) => state.detailOrder?.data);
  const statusOrder = useSelector((state) => state.detailOrder?.status);

  const [orderData, setOrderData] = useState(initialState);
  const [partnerData, setPartnerData] = useState({
    vehicle_name: '',
    license_plate: '',
    vehicle_image: [],
  });
  const [startRentDate, setStartRentDate] = useState('');
  const [endRentDate, setEndRentDate] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [durationRent, setDurationRent] = useState(0);
  const [dayDuration, setDayDuration] = useState([]);
  const [ktpImageLink, setKtpImageLink] = useState('');
  const [simImageLink, setSimImageLink] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [rentalLocationId, setRentalLocationId] = useState('');

  const [searchParams] = useSearchParams();

  const orderType = searchParams.get('type');

  const backHandler = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (orderData?.user_name.replace(/\s/g, '').length === 0) {
      return showToast({ type: 'error', message: 'Nama tidak valid' });
    } else if (!new RegExp(regexEmail).test(orderData?.email)) {
      return showToast({ type: 'error', message: 'Email tidak valid' });
    } else if (orderData?.wa_number.replace(/s/g, '').length === 0) {
      return showToast({ type: 'error', message: 'Nomor WA tidak valid' });
    } else if (!rentalLocationId) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
    }

    if (orderType === 'Daily') {
      if (!selectedType) {
        return showToast({ type: 'error', message: 'Pilih Tipe Sewa' });
      }

      if (selectedType === 1) {
        let isEmpty = {
          empty: false,
          detail: '',
        };
        dayDuration.map((item) => {
          if (!item.detail_pickup_location) {
            return (isEmpty = {
              empty: true,
              detail: 'Tulis Detail Lokasi Pengantaran',
            });
          } else if (!item.detail_drop_off_location) {
            return (isEmpty = {
              empty: true,
              detail: 'Tulis Detail Lokasi Pengembalian',
            });
          } else if (!item.booking_start_time) {
            return (isEmpty = {
              empty: true,
              detail: 'Masukan Jam Mulai',
            });
          } else if (!item.booking_end_time) {
            return (isEmpty = {
              empty: true,
              detail: 'Masukan Jam Selesai',
            });
          }
        });
        if (orderData.order_detail.start_booking_date === '') {
          return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
        } else if (!durationRent) {
          return showToast({ type: 'error', message: 'Pilih Durasi Sewa' });
        } else if (dayDuration.length !== durationRent) {
          return showToast({ type: 'error', message: 'Lengkapi Data Lokasi' });
        } else if (isEmpty.empty) {
          return showToast({ type: 'error', message: isEmpty.detail });
        }
      } else if (selectedType === 2) {
        if (orderData.order_detail.rental_delivery_location_detail === '') {
          return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengantaran' });
        } else if (orderData.order_detail.rental_return_location_detail === '') {
          return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengembalian' });
        } else if (orderData.order_detail.start_booking_date === '') {
          return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
        } else if (orderData.order_detail.end_booking_date === '') {
          return showToast({ type: 'error', message: 'Pilih Tanggal Selesai' });
        } else if (orderData.order_detail.start_booking_time === '') {
          return showToast({ type: 'error', message: 'Pilih Jam Mulai' });
        } else if (orderData.order_detail.end_booking_time === '') {
          return showToast({ type: 'error', message: 'Pilih Jam Selesai' });
        }
      }
    } else if (orderType === 'Airport Transfer') {
      if (orderData.order_detail.rental_delivery_location === '') {
        return showToast({ type: 'error', message: 'Masukan Lokasi Penjemputan' });
      } else if (orderData.order_detail.rental_return_location === '') {
        return showToast({ type: 'error', message: 'Masukan Lokasi Pengantaran' });
      } else if (orderData.order_detail.rental_delivery_location_detail === '') {
        return showToast({ type: 'error', message: 'Masukan Detail Lokasi Penjemputan' });
      } else if (orderData.order_detail.rental_return_location_detail === '') {
        return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengembalian' });
      } else if (orderData.order_detail.start_booking_date === '') {
        return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
      } else if (orderData.order_detail.end_booking_date === '') {
        return showToast({ type: 'error', message: 'Pilih Tanggal Selesai' });
      } else if (orderData.order_detail.start_booking_time === '') {
        return showToast({ type: 'error', message: 'Pilih Jam Jemput' });
      } else if (orderData.order_detail.end_booking_time === '') {
        return showToast({ type: 'error', message: 'Pilih Jam Selesai' });
      } else if (orderData.order_detail.flight_number === '') {
        return showToast({ type: 'error', message: 'Masukan No. Penerbangan' });
      }
    }

    if (partnerData.vehicle_name === '') {
      return showToast({ type: 'error', message: 'Masukan Nama Mobil' });
    } else if (partnerData.license_plate === '') {
      return showToast({ type: 'error', message: 'Masukan Plat Mobil' });
    } else if (!partnerData.vehicle_image.length) {
      return showToast({ type: 'error', message: 'Pilih Gambar Mobil' });
    } else if (!orderData.booking_price) {
      return showToast({ type: 'error', message: 'Masukan Total Pembayaran' });
    }
    const payload = {
      ...orderData,
      order_type_id: orderType === 'Airport Transfer' ? 4 : 3, // airpot transfer partner id 4, daily partner id 3
      currency: rentalLocationId?.currency,
      order_detail: {
        ...orderData.order_detail,
        without_driver: selectedType === 2 ? true : false,
        booking_zones: selectedType === 1 ? dayDuration : [],
        end_booking_time:
          selectedType === 1
            ? dayDuration[dayDuration.length - 1]?.booking_end_time
            : orderData.order_detail.end_booking_time,
        loc_time_id: rentalLocationId?.time_zone_identifier,
        location_id: rentalLocationId?.id,
      },
      partner_vehicle: {
        name: partnerData.vehicle_name,
        license_plate: partnerData.license_plate,
        partner_vehicle_image: partnerData.vehicle_image.map((item) => ({ file_name: item })),
      },
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin lanjut melakukan proses transaksi?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(createOrderBusinessPartner(payload)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Membuat Order' });
          navigate(-1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Membuat Order' });
        }
      },
    });
  };

  useEffect(() => {
    setPartnerData({
      ...partnerData,
      vehicle_image: selectedVehicle?.photo === undefined ? [] : selectedVehicle?.photo,
    });
  }, [selectedVehicle]);

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleRemoveImageVehicle = (image) => {
    if (transactionKey) return;
    const updatedPhotos =
      partnerData.vehicle_image &&
      partnerData.vehicle_image.filter((item) => {
        if (typeof item === 'string') {
          return item !== image;
        } else {
          return item.name !== image;
        }
      });
    dispatch(deleteImageVehicle(updatedPhotos));
  };

  const handleProcessOrder = () => {
    setShowConfirmation({
      message: 'Apakah anda ingin proses orderan ini ?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(
            changeOrderStatus({
              prevStatus: 'PAID',
              nextStatus: 'COMPLETED',
              transaction_key: orderData?.transaction_key,
            }),
          ).unwrap();
          showToast({ type: 'success', message: 'Berhasil Proses Order' });
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Proses Order!' });
        }
      },
    });
  };

  const handleBroadcastTask = () => {
    setShowBroadcastTask(true);
  };

  useEffect(() => {
    if (!transactionKey) return;
    dispatch(fetchOrderById(transactionKey));
  }, [transactionKey]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    if (checkEmptyObject(selectedOrder)) return;
    setOrderData(() => ({ ...initialState, ...selectedOrder }));
    setKtpImageLink(() => selectedOrder.order_detail?.identity?.ktp);
    setSimImageLink(() => selectedOrder.order_detail?.identity?.sim);
    setSelectedType(() => (selectedOrder.order_type_id === 3 && selectedOrder.order_detail.without_driver ? 2 : 1));
    setPartnerData(() => ({
      vehicle_name: selectedOrder.partner_vehicle?.name,
      license_plate: selectedOrder.partner_vehicle?.license_plate,
      vehicle_image:
        selectedOrder.partner_vehicle?.partner_vehicle_image?.map((item) => ({ ...item, name: item.file_name })) || [],
    }));
    setStartRentDate(() => parseISO(selectedOrder.order_detail.start_booking_date));
    setEndRentDate(() => parseISO(selectedOrder.order_detail.end_booking_date));
    setDurationRent(() => selectedOrder.order_detail.booking_zones?.length);
    setDayDuration(() => selectedOrder.order_detail.booking_zones);
    setRentalLocationId(() => ({ id: selectedOrder.order_detail.location_id, currency: selectedOrder.currency }));
  }, [selectedOrder]);

  useEffect(() => {
    if (statusOrder === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [statusOrder]);

  const checkIsDisable = (tasks) => {
    if (!tasks) return;

    if (selectedType === 2) {
      if (!tasks) return;

      if (tasks.length > 1) {
        const nonCancelItems = tasks.filter((item) => item.string !== 'CANCEL');

        if (nonCancelItems.length >= 2) {
          const sameTypes = new Set(nonCancelItems.map((item) => item.task_type));
          return sameTypes.size > 1;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      const nonCancelItems = tasks?.filter((item) => item.string !== 'CANCEL');
      if (nonCancelItems.length > 0) {
        if (nonCancelItems.every((item) => item.string !== 'CANCEL')) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <div className="business-partner-rental-custom-order">
        <header className="business-partner-rental-custom-order__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>

        {orderData?.order_status === 'PAID' && (
          <Button
            className="business-partner-rental-custom-order__publish-btn"
            style={{
              backgroundColor: checkIsDisable(orderData?.order_driver_tasks) ? '#e0e0e0' : '#007A05',
            }}
            onClick={handleBroadcastTask}
            disabled={checkIsDisable(orderData?.order_driver_tasks)}
          >
            Publish Task to Driver
          </Button>
        )}

        <DetailPenyewa
          orderData={orderData}
          setOrderData={setOrderData}
          numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          ktpImageLink={ktpImageLink}
          setKtpImageLink={setKtpImageLink}
          simImageLink={simImageLink}
          setSimImageLink={setSimImageLink}
          isDetail={transactionKey}
        />

        <DetailSewa
          orderData={orderData}
          setOrderData={setOrderData}
          startRentDate={startRentDate}
          setStartRentDate={setStartRentDate}
          endRentDate={endRentDate}
          setEndRentDate={setEndRentDate}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          durationRent={durationRent}
          setDurationRent={setDurationRent}
          dayDuration={dayDuration}
          setDayDuration={setDayDuration}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          isDetail={transactionKey}
          rentalLocationId={rentalLocationId}
          setRentalLocationId={setRentalLocationId}
          orderType={orderType}
        />

        <DetailBisnisPartner
          partnerData={partnerData}
          setPartnerData={setPartnerData}
          imageFiles={partnerData.vehicle_image}
          handleRemove={handleRemoveImageVehicle}
          isDetail={transactionKey}
        />

        <div className="business-partner-rental-custom-order__table-wrapper">
          <InputField
            label="Total Pembayaran"
            type="number"
            value={orderData.booking_price || ''}
            onChange={(e) => setOrderData({ ...orderData, booking_price: +e.target.value })}
            placeholder="0"
            className="input-field"
            price
            onWheel={numberInputOnWheelPreventChange}
            disable={transactionKey}
            currency={rentalLocationId.currency}
          />

          <div className="business-partner-rental-custom-order__buttons">
            <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
              Kembali
            </Button>

            {transactionKey && orderData.order_status === 'PAID' ? (
              <Button
                size="sm"
                className="button"
                width={208}
                onClick={handleProcessOrder}
                style={{ backgroundColor: '#007A05' }}
              >
                Proses Order
              </Button>
            ) : !transactionKey ? (
              <Button size="sm" className="button" width={208} onClick={handleSubmit}>
                Proses Sewa Mobil
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessPartnerCustomOrder;
