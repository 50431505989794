import { createSlice } from '@reduxjs/toolkit';
import { getAllDrivers, getDetailDriver, getDriverTransactionHistory, getAllDriversByFilter } from './actions';

const initialState = {
  data: {},
  selected: {},
  history: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const drivers = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    // reducers goes here
    resetSelectedDriver: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDrivers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllDrivers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllDrivers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailDriver.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getDetailDriver.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailDriver.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(getDriverTransactionHistory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.history = {};
      })
      .addCase(getDriverTransactionHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDriverTransactionHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.history = action.payload;
      })
      .addCase(getAllDriversByFilter.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllDriversByFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllDriversByFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { resetSelectedDriver } = drivers.actions;

export default drivers.reducer;
