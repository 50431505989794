import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from 'icons/arrow-down-icon.svg';
import { ReactComponent as XCircleIcon } from 'icons/x-circle-filled.svg';
import ClickOutside from '../ClickOutside';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

const FilterTransaction = ({ multiple = true, selected, setPage, className, placeholder, params, paramName }) => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const changeStatusHandler = (e) => {
    const { checked, id } = e.target;
    const filtered = selected.find((item) => item.id == id);

    if (checked) {
      if (params && params?.toLowerCase() !== 'all') {
        setSearchParams(() => {
          let queryString = [];
          for (const [key, value] of searchParams.entries()) {
            if (key == paramName) {
              queryString.push(`${key}=${multiple ? params.concat(',', filtered.id) : filtered.id}`);
            } else {
              queryString.push(`${key}=${value}`);
            }
          }
          return queryString.join('&');
        });
      } else {
        setSearchParams(() => {
          let queryString = [];
          for (const [key, value] of searchParams.entries()) {
            if (key == paramName) {
              queryString.push(`${key}=${filtered.id}`);
            } else {
              queryString.push(`${key}=${value}`);
            }
          }
          return queryString.join('&');
        });
      }
    } else if (checked === false) {
      const newParams = params.split(',').filter((item) => item != filtered.id);
      if (newParams.length > 0) {
        setSearchParams(() => {
          let queryString = [];
          for (const [key, value] of searchParams.entries()) {
            if (key == paramName) {
              queryString.push(`${key}=${newParams.toString()}`);
            } else {
              queryString.push(`${key}=${value}`);
            }
          }
          return queryString.join('&');
        });
      } else {
        setSearchParams(() => {
          let queryString = [];
          for (const [key, value] of searchParams.entries()) {
            if (key == paramName) {
              queryString.push(`${key}=ALL`);
            } else {
              queryString.push(`${key}=${value}`);
            }
          }
          return queryString.join('&');
        });
      }
    }
    setPage(1);
  };

  const removeStatusHandler = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { id } = e.currentTarget.parentElement;
    const splitId = id.split('-')[0];
    // setSelected((prev) => prev.map((item) => (item.id == id ? { ...item, value: false } : item)));
    const paramsToArray = params.split(',');
    if (paramsToArray.length > 1) {
      setSearchParams(() => {
        let queryString = [];
        for (const [key, value] of searchParams.entries()) {
          if (key == paramName) {
            queryString.push(`${key}=${paramsToArray.filter((item) => item != splitId).join(',')}`);
          } else {
            queryString.push(`${key}=${value}`);
          }
        }
        return queryString.join('&');
      });
    } else {
      setSearchParams(() => {
        let queryString = [];
        for (const [key, value] of searchParams.entries()) {
          if (key == paramName) {
            queryString.push(`${key}=ALL`);
          } else {
            queryString.push(`${key}=${value}`);
          }
        }
        return queryString.join('&');
      });
    }
    setPage(1);
  };

  return (
    <div className={clsx('filter-transaction', className)}>
      <div onClick={() => setOpen(!open)} className="filter-transaction-button">
        {selected.find((item) => item.value === true) ? (
          <div
            className="filter-transaction-list"
            style={{ paddingBottom: params?.split(',').length > 1 ? '10px' : '' }}
          >
            {selected.map(
              (item, idx) =>
                item.value && (
                  <div key={idx} className="filter-transaction-list-item" id={item.id + '-selected'}>
                    {item.name}
                    {multiple && <XCircleIcon className="remove-icon" onClick={removeStatusHandler} />}
                  </div>
                ),
            )}
          </div>
        ) : (
          <span className="filter-transaction-title">{placeholder}</span>
        )}
        <ArrowDown className="filter-transaction-icon" />
      </div>

      {open && (
        <ClickOutside onClickOutside={() => setOpen(false)}>
          <div className="filter-transaction-modal">
            {selected.map((item) => (
              <div key={item.id} className="filter-transaction-item">
                <input
                  checked={item.value}
                  type={multiple ? 'checkbox' : 'radio'}
                  className="filter-transaction-checkbox"
                  id={item.id}
                  name={multiple ? item.name : 'radio-selection'}
                  onChange={changeStatusHandler}
                />
                <label htmlFor={item.id}>{item.name}</label>
              </div>
            ))}
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default FilterTransaction;
