import React, { useMemo } from 'react';
import { TableWrapper, Table, PaginationTable } from 'components/Global';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  deleteDeliveryLocation,
  getAllDeliveryLocations,
  getDeliveryLocationDetail,
} from 'features/delivery-location/actions';
import { getDeliveryLocations, isDeliveryLocationsLoading, resetSelection } from 'features/delivery-location/slice';
import { getAllRentalLocation, getRentalLocationById } from 'features/rental-location/actions';
import FilterBar from './FilterBar';
import { useSearchParams } from 'react-router-dom';
import LoadingText from 'components/Global/LoadingText';
import EmptyState from 'components/Global/EmptyState';
import ButtonAdd from 'components/Global/ButtonAdd';

const DeliveryComponent = () => {
  const { showToast, setShowAddDeliveryLocation, setShowConfirmation } = useAppContext();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: parentLocations } = useSelector((state) => state.rentalLocation);
  const { shuttle: deliveryLocations, pagination } = useSelector(getDeliveryLocations);
  const isLoading = useSelector(isDeliveryLocationsLoading);

  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Nama Daerah', value: 'name' },
    { header: 'Daerah Sewa', value: 'city' },
    { header: 'Harga Tambahan', value: 'fee' },
  ];

  const combinedLocationData = useMemo(() => {
    if (!deliveryLocations) return;

    return deliveryLocations.map((itemA) => {
      const matchingParentLocation = parentLocations.find((itemB) => itemB.id === itemA.location_id);
      return { ...itemA, city: matchingParentLocation ? matchingParentLocation.name : '' };
    });
  }, [deliveryLocations, parentLocations]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  const page = searchParams.get('page') ?? 1;
  const locationId = searchParams.get('locationId');

  useEffect(() => {
    dispatch(getAllDeliveryLocations({ currentPage: Number(page), id: locationId }));
  }, [page, locationId]);

  const handleDeleteGarage = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    if (!id) return;

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus lokasi?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteDeliveryLocation(id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menghapus data' });
          dispatch(getAllDeliveryLocations({ currentPage: page, id: locationId }));
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Menghapus data' });
        }
      },
    });
  };

  const handleEditLocation = (e) => {
    const locationId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(getDeliveryLocationDetail(locationId));
    setShowAddDeliveryLocation(() => ({ show: true, method: 'edit' }));
    const getLocation = combinedLocationData?.find((item) => item.id === +locationId);
    if (!getLocation) return;
    dispatch(getRentalLocationById(getLocation?.location_id));
  };

  const handleAdd = () => {
    setShowAddDeliveryLocation(() => ({ method: 'add', show: true }));
    dispatch(resetSelection());
  };

  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  if (isLoading) {
    return <LoadingText />;
  }

  return (
    <div className="delivery">
      <ButtonAdd onClick={handleAdd} label="Tambah Lokasi" />

      {!deliveryLocations ? (
        <EmptyState />
      ) : (
        <TableWrapper
          icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
          title="Lokasi Pengantaran"
          CustomFilterComponent={<FilterBar />}
        >
          <Table
            column={column}
            data={combinedLocationData}
            actionBtn
            onEdit={handleEditLocation}
            onDel={handleDeleteGarage}
            currentPage={page}
          />
          <PaginationTable
            currentPage={Number(page)}
            totalCount={pagination.total_data}
            onPageChange={(newPage) => setSearchParams({ ...params, page: newPage })}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default DeliveryComponent;
