import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const createPublishTask = createAsyncThunk('publishTask/createPublishTask', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { key, ...payloadData } = payload;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/orders/${key}/publish`,
      { ...payloadData },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
