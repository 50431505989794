import React from 'react';
import { InputField } from 'components/Global';
import { setCurrency } from 'utils/functionality';
import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import SelectFieldCar from 'components/Global/SelectFieldCar';
import { useSelector } from 'react-redux';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';

const RincianBiaya = ({
  selectedCar,
  setSelectedCar,
  vehicleInput,
  setVehicleInput,
  orderData,
  setOrderData,
  summaryData,
  baggage,
  setBaggage,
}) => {
  const { data: vehicleList } = useSelector((state) => state.vehicle);
  const { data: rentalServicesData } = useSelector((state) => state.services);

  return (
    <div className="detail-order-with-driver__price-detail">
      <div className="detail-order-with-driver__price-detail-title">
        <h1>Rincian Biaya</h1>
      </div>
      <div className="detail-order-with-driver__price-detail-body">
        <SelectFieldCar
          label="Pilih Mobil"
          htmlFor="pilih-mobil"
          placeholder="Cari Mobil.."
          data={vehicleList.vehicles}
          selectedCar={selectedCar}
          onSelectCar={setSelectedCar}
          value={vehicleInput}
          onChange={(e) => setVehicleInput(e.target.value)}
          onClick={(vehicleId, resetVehicle) => {
            if (vehicleId === '') {
              setVehicleInput(resetVehicle);
              return;
            }
            setOrderData({ ...orderData, order_detail: { ...orderData.order_detail, vehicle_id: vehicleId } });
            setVehicleInput(resetVehicle);
          }}
          payloadFilter={{
            locationRental: orderData?.order_detail?.location_id,
            startTrip: `${orderData?.order_detail?.start_booking_date} ${orderData?.order_detail?.start_booking_time}`,
            endTrip: `${orderData?.order_detail?.end_booking_date} ${orderData?.order_detail?.end_booking_time}`,
            supportDriver: true,
            customOrder: true,
            page: 0,
            limit: 0,
            order_id: orderData.transaction_key,
            subServiceId: rentalServicesData
              ?.find((item) => item.name === 'Sewa Mobil')
              ?.sub_services?.find((item) => item.name === 'Daily')
              ?.facilities?.find((item) => item.name === 'With Driver')?.id,
          }}
          disable={
            orderData.order_detail.start_booking_date === '' ||
            orderData.order_detail.end_booking_date === '' ||
            orderData.order_detail.start_booking_time === '' ||
            orderData.order_detail.end_booking_time === ''
          }
        />
        <SelectFieldDropdown
          label="Jumlah Penumpang"
          htmlFor="pilih-jumlah-penumpang"
          placeholder="Pilih Jumlah Penumpang"
          icon={<CarChair />}
          data={
            selectedCar
              ? Array.from({ length: selectedCar?.max_passanger + 1 }, (_, idx) => idx + 1).map((item) => ({
                  id: item,
                  name: `${item} Orang`,
                }))
              : []
          }
          value={orderData.order_detail.passenger_number ?? ''}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, passenger_number: +e.target.id },
            })
          }
          disable={!selectedCar}
        />
        <div className="detail-order-with-driver__baggage">
          <InputField label="Kapasitas Koper" disabled value={selectedCar?.max_suitcase ?? '-'} />
          <InputField
            label="Koper yang dibawa"
            value={baggage ?? ''}
            onChange={(e) => setBaggage(isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value))}
          />
        </div>
        <InputField
          label="Biaya Sewa Mobil"
          disabled
          value={setCurrency(summaryData?.booking_price, orderData?.currency) || '-'}
        />
        <InputField label="Deposit" disabled value={setCurrency(summaryData?.deposit, orderData?.currency) || '-'} />
        <InputField
          label="Overtime"
          disabled
          value={setCurrency(summaryData?.order_overtime_total_price, orderData?.currency) || '-'}
        />
        <InputField
          label="One Day Charge"
          disabled
          value={setCurrency(summaryData?.one_day_order_charge, orderData?.currency) || '-'}
        />
        <InputField
          label="Outside Operational Hour Charge"
          disabled
          value={setCurrency(summaryData?.outside_operational_charge, orderData?.currency) || '-'}
        />
        <InputField
          label="Exceed Max Passenger Charge"
          disabled
          value={setCurrency(summaryData?.exced_max_passenger_charge, orderData?.currency) || '-'}
        />
        <InputField
          label="Tipe Pembayaran"
          disabled
          value={
            orderData?.type === 'HALF'
              ? `Pembayaran DP ${
                  orderData?.order_detail?.dp_rule?.formula_percentage?.value
                    ? `${orderData.order_detail.dp_rule.formula_percentage.value}%`
                    : ''
                }`
              : 'Pembayaran Full'
          }
        />
        {orderData?.type === 'HALF' ? (
          <>
            <InputField
              label={`Biaya Bayar DP ${
                orderData?.order_detail?.dp_rule?.formula_percentage?.value
                  ? `${orderData.order_detail.dp_rule.formula_percentage.value}%`
                  : ''
              }`}
              disabled
              value={setCurrency(summaryData?.total_dp, orderData?.currency) || '-'}
            />
            <InputField
              label="Biaya selanjutnya yang harus dibayar"
              disabled
              value={setCurrency(summaryData?.remainder, orderData?.currency) || '-'}
            />
          </>
        ) : null}
        <InputField
          label="Pembayaran Dengan Point"
          disabled
          value={summaryData?.point}
          icon={<PointsIcon />}
          iconPosition="start"
        />
        {orderData.type === 'HALF' ? (
          <InputField
            label="Status Pembayaran DP"
            disabled
            value={orderData?.payment_time === null ? 'Belum Lunas' : 'Lunas' || '-'}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RincianBiaya;
