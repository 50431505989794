import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as FileIcon } from 'icons/file-icon.svg';
import Thumbnail from './Thumbnail';
import { uploadImageVehicle } from 'features/vehicle/action';
import { useDispatch } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import clsx from 'clsx';

// limit size of image file in byte
const limitSize = 1048576;

const UploadVehicleImage = ({ imageFiles, handleRemove, disable }) => {
  const dispatch = useDispatch();

  // context
  const { showToast } = useAppContext();

  const targetRef = useRef(null);
  const [imageList, setImageList] = useState([]);

  // drag active state
  const [dragActive, setDragActive] = React.useState(false);

  // will set image list if Image files not empty
  useEffect(() => {
    if (imageFiles === undefined) return;

    const data = imageFiles.map((image, i) => ({
      id: image.id ? image.id : i,
      name: image.name ? image.name : image,
      url: image.name ? process.env.REACT_APP_IMAGES + image.name : process.env.REACT_APP_IMAGES + image,
      show: false,
    }));

    setImageList(data);
    return;
  }, [imageFiles]);

  // function to loop through Files and upload
  const uploadImages = (files, limitSize) => {
    for (let image in files) {
      // error handler
      if (files[image].size >= limitSize) {
        showToast({ type: 'error', message: 'Ukuran File Terlalu Besar' });
        return;
      }

      // create obj image and upload image
      if (image !== 'length' && image !== 'item') {
        const newObjImages = {
          url: URL.createObjectURL(files[image]),
          name: `rentcar/development/cars/cars/${
            files[image].name.split(' ').length > 1 ? files[image].name.split(' ').join('-') : files[image].name
          }`,
          id: Math.random() + 1 + '', // temporary id
          show: false,
        };

        // add to image list
        const updatedImagelist = [...imageList, newObjImages];
        setImageList(updatedImagelist);

        // upload file
        dispatch(uploadImageVehicle(files[image]));
      }
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // add iamge to imagelist when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const newImages = e.dataTransfer.files;
      // check if there are files
      if (newImages) {
        uploadImages(newImages, limitSize);
      }
    }
  };

  // add image to imagelist when file is selected with click
  const onImageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newImages = e.target.files;
    // check if there are files
    if (newImages) {
      uploadImages(newImages, limitSize);
    }
  };

  return (
    <div className={clsx('upload-vehicle-image__container', disable && 'disable')} onDragEnter={handleDrag}>
      <input
        type="file"
        name="upload-image"
        multiple
        id="upload-image"
        accept="image/*"
        hidden
        ref={targetRef}
        onChange={onImageChange}
        disabled={disable}
      />
      <label className="button" htmlFor="upload-image">
        <div className={dragActive ? 'active' : ''}>
          <FileIcon />
          <p>
            Drop your image here, or{' '}
            <button type="button" onClick={() => targetRef.current.click()}>
              browse
            </button>
          </p>
        </div>
      </label>
      {dragActive && (
        <div
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
      <div className="upload-vehicle-image__thumbnails">
        {imageList.map((img, idx) => (
          <Thumbnail
            key={idx}
            filename={img.name}
            setState={setImageList}
            image={img.url}
            show={img.show}
            id={img.id}
            handleRemove={handleRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default UploadVehicleImage;
