import ButtonAdd from 'components/Global/ButtonAdd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BusinessPartnerAdminList from './AdminList';
import { resetSelectedAdmin } from 'features/business-partner/vendorAdminSlice';

const BusinessPartnerAdminComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAdd = () => {
    dispatch(resetSelectedAdmin());
    navigate('/business-partner-admin/add-admin');
  };

  return (
    <div className="business">
      <ButtonAdd onClick={handleAdd} label="Tambah Admin" />

      <BusinessPartnerAdminList />
    </div>
  );
};

export default BusinessPartnerAdminComponent;
