import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField } from 'components/Global';
import SelectFieldBanks from 'components/Global/SelectFieldBanks';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { convertBase64toImage } from 'utils/functionality';
import { getGMTOffsetString } from 'utils/functionality';
import timeZones from 'utils/time-zones.json';

// PAYMENT METHOD ARRAY
const paymentMethod = [
  {
    id: 1,
    method: 'Bank Transfer',
    description: 'Bank Manual Transfer',
    code: 'BCA',
  },
];

// REGEX VALIDATION
// const regexAccountBankNumber = /^(\d{3,12})$/g;

const MetodePembayaran = ({
  prevPage,
  nextPage,
  orderData,
  setOrderData,
  paymentImageLink,
  setPaymentImageLink,
  isOrderConfirmation,
  orderDataLocalStorage,
  setOrderDataLocalStorage,
}) => {
  // CONTEXT
  const { showToast } = useAppContext();

  // GLOBAL STATE
  const { data: dataBanks } = useSelector((state) => state.banks);
  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);

  // LOCAL STATE
  const [paymentBankName, setPaymentBankName] = useState('');

  // CHANGE FUNCTION
  const changeHandler = (e, propsName) => {
    const newOrderData = {
      ...orderData,
      disbursement: {
        ...orderData.disbursement,
        [propsName]: propsName === 'payment_method_id' ? parseInt(e.target.id) : e.target.value,
      },
    };
    setOrderData(newOrderData);
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
  };

  // FUNCTION TO HANDLE UPLOAD IMAGE
  const handleImage = async (imageFile) => {
    const convertedImage = await convertBase64toImage(imageFile.file, 'identity-image');
    setPaymentImageLink(convertedImage);

    const newOrderData = {
      ...orderData,
      disbursement: { ...orderData.disbursement, disbursement_confirmation_image: imageFile.file },
    };

    setOrderData(newOrderData);
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, paymentImageLink: imageFile.file });
  };

  // SCROLL TO TOP WHEN COMPONENT IS LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // FUNCTION CLICK DROPDOWN BANKS
  useEffect(() => {
    if (paymentBankName === '') return;
    setOrderData({ ...orderData, disbursement: { ...orderData.disbursement, sender_bank_name: paymentBankName } });
  }, [paymentBankName]);

  // FUNCTION TO SUBMIT PAYMENT
  const submitHandler = (e) => {
    e.preventDefault();

    // some validation
    const validPaymentMethod = orderData.disbursement.payment_method_id !== 0;
    const discoveredBank = dataBanks?.data.find((item) => item.name === orderData.disbursement.sender_bank_name);
    const validPaymentBank = orderData.disbursement.sender_bank_name !== '' && discoveredBank;
    const validAccountNumber = orderData.disbursement.sender_bank_number != 0;
    const validImagePayment = orderData.disbursement.disbursement_confirmation_image !== '';

    if (!validPaymentMethod) {
      return showToast({ type: 'error', message: 'Pilih Metode Pembayaran' });
    } else if (!validPaymentBank) {
      return showToast({ type: 'error', message: 'Pilih Bank Pembayaran' });
    } else if (!validAccountNumber) {
      return showToast({ type: 'error', message: 'Nomor Rekening Tidak Valid' });
    } else if (!validImagePayment) {
      return showToast({ type: 'error', message: 'Upload Bukti Pembayaran' });
    }

    const locationTimeZone = timeZones.find(
      (item) => item.offsetString === getGMTOffsetString() && item.friendlyName.toLowerCase().includes('indonesia'),
    );
    setOrderData({
      ...orderData,
      disbursement: { ...orderData.disbursement, time_zone: locationTimeZone?.longTimezones[0] },
    });

    nextPage();
    setOrderDataLocalStorage({ ...orderDataLocalStorage, page: 4 });
  };

  // SET SUMMARY DATA TO LOCAL STORAGE
  useEffect(() => {
    setOrderDataLocalStorage({ ...orderDataLocalStorage, summary_order_data: summaryOrderData });
  }, [summaryOrderData]);

  return (
    <form className="metode-pembayaran">
      {/* METODE PEMBAYARAN FORM */}
      <div className="metode-pembayaran__form">
        <h2 className="metode-pembayaran__form__title">Detail Pembayaran</h2>
        <div className="metode-pembayaran__form__form">
          <SelectFieldDropdown
            data={paymentMethod}
            label="Metode Pembayaran"
            htmlFor="metode-pembayaran"
            placeholder="Pilih Metode Pembayaran"
            value={orderData.disbursement.payment_method_id}
            onChange={(e) => changeHandler(e, 'payment_method_id')}
            disable={isOrderConfirmation}
          />
          <SelectFieldBanks
            htmlFor="metode-pembayaran-bank"
            label="Nama Bank"
            placeholder="Cari Bank..."
            data={dataBanks.data}
            value={orderData.disbursement.sender_bank_name}
            onChange={(e) => changeHandler(e, 'sender_bank_name')}
            onClick={setPaymentBankName}
            disable={isOrderConfirmation}
          />
          <InputField
            label="Nama Pemilik rekening"
            htmlFor="nama-pemilik-rekening"
            placeholder="Tulis Nama "
            value={orderData.disbursement.sender_name}
            onChange={(e) => changeHandler(e, 'sender_name')}
            disable={isOrderConfirmation}
          />
          <InputField
            type="number"
            label="Nomor Rekening"
            htmlFor="detail-deposit-nomor-rekening"
            placeholder="Tulis Nomor Rekening"
            value={orderData.disbursement.sender_bank_number || ''}
            onChange={(e) => changeHandler(e, 'sender_bank_number')}
            disable={isOrderConfirmation}
          />
          {/* upload image */}
          <div>
            <div>
              <h3>Bukti Transfer</h3>
            </div>
            <UploadImageCustomOrder
              htmlFor="bukti-transfer"
              uploadAction={handleImage}
              base64={true}
              imageFile={paymentImageLink}
              disable={isOrderConfirmation}
              customOrder
            />
          </div>
        </div>
      </div>

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="metode-pembayaran__button-action">
          <Button
            type="button"
            width="165px"
            height="39px"
            variant="outline"
            size="md"
            className="button"
            onClick={() => {
              prevPage();
              setOrderDataLocalStorage({ ...orderDataLocalStorage, page: 2 });
            }}
          >
            Kembali
          </Button>
          <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
            Lanjutkan
          </Button>
        </div>
      )}
    </form>
  );
};

export default MetodePembayaran;
